import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import {
    GridLayout,
    GridLayoutItem,

} from "@progress/kendo-react-layout";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import moment from 'moment';
import { Chart, ChartCategoryAxisItem, ChartCategoryAxis, ChartArea, ChartLegend, ChartSeries, ChartSeriesItem, ChartValueAxis, ChartValueAxisItem, ChartTooltip, ChartNavigatorSeriesNotesLabel } from "@progress/kendo-react-charts";
import 'hammerjs';
import axios from "axios";
import krMessages from "./kr.json";
import { orderBy } from "@progress/kendo-data-query";

import { DatePicker } from "@progress/kendo-react-dateinputs";
import {
    IntlProvider,
    load,
    LocalizationProvider,
    loadMessages,
} from "@progress/kendo-react-intl";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/ko/numbers.json";
import currencies from "cldr-numbers-full/main/ko/currencies.json";
import caGregorian from "cldr-dates-full/main/ko/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/ko/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/ko/timeZoneNames.json"
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { RadioGroup } from '@progress/kendo-react-inputs';
import { Divider } from '@mui/material';
import 'react-medium-image-zoom/dist/styles.css';
import { MultiSelect } from "@progress/kendo-react-dropdowns";
load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    currencies,
    caGregorian,
    dateFields,
    timeZoneNames
);
loadMessages(krMessages, "ko-KR");


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}


function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}


const ProgressBody = ({ AreaName, setplanvalue, setactualvalue, ActData }) => {


    const AreaName2 = AreaName && AreaName['name']

    const { height, width } = useWindowDimensions();

    const { project_code } = useParams();
    const [weekdata, setweekdata] = useState([]);
    const [dayweight, setdayweight] = useState([]);
    const [dayweight2, setdayweight2] = useState([]);
    const [ddata, setddata] = useState([]);
    const [Discipline, setdListdata] = useState([]);
    const defaultvalue = new Date()
    const [value, setValue] = useState(defaultvalue);

    const [checked, setChecked] = React.useState('건축');
    const [checked2, setChecked2] = React.useState('건축');

    const [Mvalue, setMValue] = React.useState([]);



    function getDates(startDate, endDate) {
        //first the function and variable definitions
        let dates = []
        const addDays = function (days) {
            var date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        };
        //now our Sunday check
        let currentDate = startDate
        if (currentDate.getDay() > 0) {
            currentDate.setDate(currentDate.getDate() - currentDate.getDay());
        }

        while (currentDate <= endDate) {
            dates.push({
                // week: moment(currentDate).format('YY년 MM월 WW주'),
                week: moment(currentDate).format("YY년 WW주")
            });
            currentDate = addDays.call(currentDate, 7);
        }
        return dates;
    };



    useEffect(() => {
        const fetchData = async () => {

            // const res = await axios.get(`${process.env.REACT_APP_BASEURL}/getJSON?path=${process.env.REACT_APP_FILEPATH}/files/Activity2/${project_code}.json`)

            let body =
            {
                "bpname": "Project Information",
            }
            const res = await axios.post(`${process.env.REACT_APP_BASEURL}/getbprecords?path=${project_code}`, body)

            const progressBase = res.data.data.data[0] && res.data.data.data[0]['ProgressBaseType']

            const datafrom = ActData
                .filter(v => v.WBSCodeNMLv2 === AreaName2.split('//')[0] && v.WBSCodeNMLv3 === AreaName2.split('//')[1])

            const res4 = await axios.get(`${process.env.REACT_APP_BASEURL}/getJSON?path=${process.env.REACT_APP_FILEPATH}/files/conf/${project_code}.json`)
            const dd_line = res4.data.filter(v => typeof v._bp_lineitems !== 'undefined' && AreaName2.includes(v.FloorText) && AreaName2.includes(v.RoomText)).reduce((c, v) => c.concat(v._bp_lineitems.map(o => Object.assign(o, { FloorText: v.FloorText, RoomText: v.RoomText, FloorAreaPhotoRT: v.FloorAreaPhotoRT }))), [])

            setddata(dd_line)


            const d_list =
                // _.sortBy(_.uniqBy(datafrom, 'WBSCodeNMLv4').map((v) => ({ ...v, ...dd_line.find(vf => vf.DashDiscSPD === v.WBSCodeNMLv4) })), 'DashSequenceSPD').map((v) => ({ value: v.WBSCodeNMLv4, label: v.WBSCodeNMLv4 }))
                await axios.get(`${process.env.REACT_APP_BASEURL}/getJSON?path=${process.env.REACT_APP_FILEPATH}/files/Activity2/${project_code}_disc.json`)

            setdListdata(d_list.data)

            const dayweight = datafrom
                .map((v) => ({ ...v, PALPlanQty: progressBase ==='예상물량'&&  v.PALForecastQty !== 0 && v.PALPlanQty !== v.PALForecastQty ? v.PALForecastQty : v.PALPlanQty }))
                .map((v) => {
                    return {
                        Activity: v.uuu_P6ActivityId + v.PALStepNM,
                        Type: v.WBSCodeNMLv6,
                        uniq: v.WBSCodeNMLv4 + "//" + v.WBSCodeNMLv6,
                        disc: v.WBSCodeNMLv4,
                        Area: v.WBSCodeNMLv2,
                        Subarea: v.WBSCodeNMLv3,
                        PALStepPlanStart: v.PALStepPlanStart,
                        PALStepPlanFinish: v.PALStepPlanFinish,
                        WeightValue: v.WeightValue,
                        PALPlanQty: v.PALPlanQty,
                        curve: v.ResourceCurveName,
                        PALStepActualStart: v.PALStepActualStart,
                        PALStepActualFinish: v.PALStepActualFinish,

                        plandateList: v._bp_lineitems.filter(v => v.uuu_tab_id === '일별계획물량').map((f, index) => ({
                            date: f.ActLinePlanDO.slice(0, 10),
                            dayWV: v.WeightValue * (f.ActPlanDailyQty / v.PALPlanQty)

                        })),

                        actualdateList: v._bp_lineitems.filter(v => v.uuu_tab_id === '일별실적물량').map((f, index) => ({
                            date: f.ActLineActualDO.slice(0, 10),
                            dayEV: v.WeightValue * (f.ActActualDailyQty / v.PALPlanQty),

                        })),
                        rate: v.PALActualProgress,
                        Evalue: v.PALActualProgress * v.WeightValue / 100,
                        Pvalue: v.PALPlanProgress * v.WeightValue / 100,

                    }
                })



            const dayweight2 = dayweight.filter(v => v.disc === checked2)


            const actinfo = dayweight.map((v) => ({ Activityinfo: v.Activity, disc: v.disc, Area: v.Area, Type: v.Type, }))
            
            console.log(actinfo);

            setdayweight(dayweight)
            setdayweight2(dayweight2)


            const daily_e = dayweight2.filter(v => typeof v.actualdateList !== 'undefined' && v.actualdateList !== null)
                .reduce((c, v) => c.concat(v.actualdateList && v.actualdateList
                    // .map((a)=>({...a, date:moment(a.date).format('YYYY-MM-DD')}))
                    .map(o => Object.assign(o, { dayEV: o.dayEV, uniq: v.Activity + '//' + o.date, actualdate: o.date }))), [])


            const daily_p = dayweight2.filter(v => typeof v.plandateList !== 'undefined' && v.plandateList !== null)
                .reduce((c, v) => c.concat(v.plandateList
                    // .map((a)=>({...a, date:moment(a.date).format('YYYY-MM-DD')}))
                    .map(o => Object.assign(o, { dayWV: o.dayWV, uniq2: v.Activity + '//' + o.date, plandate: o.date }))), [])

            const daily_uniq_list = _.uniq([...daily_e.map(v => v.uniq), ...daily_p.map(v => v.uniq2)])



            const daily_list = _.sortBy(daily_uniq_list.map((v) => ({ Activity: v.split('//')[0], date: v.split('//')[1], ...daily_e.find(vf => vf.uniq === v), uniq: v }))
                .map((v) => ({ ...v, ...daily_p.find(vf => vf.uniq2 === v.uniq) }))
                .map((v) => ({ ...v, ...actinfo.find(vf => vf.Activityinfo === v.Activity) }))
                .map((v) => ({
                    ...v, weekType: moment(v.date).format("YY년 WW주") +"//" + v.Type, order: moment(v.date).format('YYYYMMDD')
                })), 'order')



            const monthcalcu = datafrom.map((v) => ({ weekdate: new Date(moment(v.PALStepPlanStart.slice(0, 10)).format('YYYY-MM-DD')) }))
            const monthcalcu2 = datafrom.map((v) => ({ weekdate: new Date(moment(v.PALStepPlanFinish.slice(0, 10)).format('YYYY-MM-DD')) }))
            const monthcalcu3 = datafrom.map((v) => ({
                weekdate: v.PALStepActualStart !== null ? new Date(moment(v.PALStepActualStart).format('YYYY-MM-DD')) : new Date()
            }))
            const monthcalcu4 = datafrom.map((v) => ({
                weekdate: v.PALStepActualFinish !== null ? new Date(moment(v.PALStepActualFinish).format('YYYY-MM-DD')) : new Date()
            }))

            const minday2 = _.sortBy([...monthcalcu, ...monthcalcu2, ...monthcalcu3, ...monthcalcu4], 'weekdate')[0] && _.sortBy([...monthcalcu, ...monthcalcu2, ...monthcalcu3, ...monthcalcu4], 'weekdate')[0]['weekdate']
            const maxdays2 = _.sortBy([...monthcalcu, ...monthcalcu2, ...monthcalcu3, ...monthcalcu4], 'weekdate').reverse()[0] && _.sortBy([...monthcalcu, ...monthcalcu2, ...monthcalcu3, ...monthcalcu4], 'weekdate').reverse()[0]['weekdate']


            const weeklist = getDates(minday2, maxdays2)


            var weekdata_soure =
                _(daily_list)
                    .groupBy('weekType')
                    .map((objs, key) => ({
                        'week': key.split('//')[0],
                        'Type': key.split('//')[1],
                        'weekPV': _.sumBy(objs, 'dayWV'),
                        'weekEV': _.sumBy(objs, 'dayEV') ? _.sumBy(objs, 'dayEV') : 0,
                    }))
                    .value();

            setweekdata(weekdata_soure)
            setMValue(_.uniq(weekdata_soure.map(v => v.Type)).slice(-4))

        };

        fetchData();

    }, [project_code, AreaName2, checked2]);



    const actualpercent_by_disc = (_.sumBy(dayweight2.filter(v => v.disc === checked2)
    .map((v) => ({ ...v, EV: _.sumBy(v.actualdateList, 'dayEV') }))
    , 'EV') / _.sumBy(dayweight2.filter(v => v.disc === checked2), 'WeightValue') * 100)

    const planpercent_by_disc = ( _.sumBy(dayweight2.filter(v => v.disc === checked2)
    .map((v) => ({ ...v, PV: _.sumBy(v.plandateList.filter(v => Number(moment(v.date).format('YYYYMMDD')) <= Number(moment(value).format('YYYYMMDD'))), 'dayWV') }))
    , 'PV') / _.sumBy(dayweight2.filter(v => v.disc === checked2), 'WeightValue')  * 100)


    const weekTypeList = _.uniq(weekdata.map(v => v.Type))

    const min = new Date(moment().add(-2, 'months'));

    var groupbyType =
        _(dayweight2)
            .groupBy('uniq')
            .map((objs, key) => ({
                'Type': key.split('//')[1],
                '공종': key.split('//')[0],
                '총물량': _.sumBy(objs, 'WeightValue'),
                '누적계획물량': (_.sumBy(objs.map(v => v.plandateList).flat(1).filter(v => Number(moment(v.date).format('YYMMDD')) <= Number(moment(value).format('YYMMDD'))), 'dayWV') / _.sumBy(objs, 'WeightValue'))*100,
                '일계획물량': (_.sumBy(objs.map(v => v.plandateList).flat(1).filter(v => moment(v.date).format('YYMMDD') === moment(value).format('YYMMDD')), 'dayWV') / _.sumBy(objs, 'WeightValue'))*100,
                '누적실적물량': (_.sumBy(objs.map(v => v.actualdateList).flat(1).filter(v => Number(moment(v.date).format('YYMMDD')) <= Number(moment(value).format('YYMMDD'))), 'dayEV') / _.sumBy(objs, 'WeightValue'))*100,
                '일실적물량': (( _.sumBy(objs.map(v => v.actualdateList).flat(1).filter(v => moment(v.date).format('YYMMDD') === moment(value).format('YYMMDD')), 'dayEV') === undefined ? 0 : _.sumBy(objs.map(v => v.actualdateList).flat(1).filter(v => moment(v.date).format('YYMMDD') === moment(value).format('YYMMDD')), 'dayEV')) / _.sumBy(objs, 'WeightValue'))*100,
            }))
            .value();


    const quantity = groupbyType
        .map((v) => ({ ...v, '일일차이': v['일실적물량'] - v['일계획물량'], '누적차이': v['누적실적물량'] - v['누적계획물량'] }))
        .filter(v => v.공종 === checked2)

        console.log(quantity)

    const actualpercent = (_.sumBy(dayweight.map((v) => ({ ...v, EV: _.sumBy(v.actualdateList, 'dayEV') })), 'EV') 
    / _.sumBy(dayweight, 'WeightValue') * 100)
    const planpercent = (_.sumBy(dayweight.map((v) => ({ ...v, PV: _.sumBy(v.plandateList.filter(v => Number(moment(v.date).format('YYYYMMDD'))
     <= Number(moment(value).format('YYYYMMDD'))), 'dayWV') })), 'PV') / _.sumBy(dayweight, 'WeightValue') * 100)


    const handleChangeM = (event) => {
        setMValue(event.value);
    };



    useEffect(() => {
        setplanvalue(planpercent)
        setactualvalue(actualpercent)
    }, [actualpercent, planpercent, setactualvalue, setplanvalue])

    const handleChange = (event) => {
        setValue(event.target.value);
    };


    const [visible, setVisible] = React.useState(false);

    const [sort, setSort] = useState([
        { field: '누적차이', dir: 'asc' }]);

    const [sort2, setSort2] = useState([
        { field: 'MainIssueDO', dir: '"desc"' }]);


    let cumpv = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let cumev = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];


    const handleChangeR = (e) => {
        setChecked(e.value);
    };


    const onDeleteData = () => {
        setChecked2(checked);
        setVisible(!visible);
    };

    const toggleDialog = () => {
        setVisible(!visible);
    };


    const CustomCell = (props) => {
        const field = props.field || "";
        const value = props.dataItem[field];

        return (
            <td style={{textAlign:'right', paddingRight:6}}
            >
                {value === null || isNaN(value) ? "" : Number(value.toFixed(2)).toLocaleString()}%
            </td>
        );
    };

    const CustomCell2 = (props) => {
        const field = props.field || "";
        const value = props.dataItem[field];

        return (
            <td style={{textAlign:'right', paddingRight:6}}
            >
                {value === null || isNaN(value) ? "" : Number(value.toFixed(0)).toLocaleString()}
            </td>
        );
    };



    const CustomCellColor = (props) => {
        const field = props.field || "";
        const type = props.dataItem[field];

        const plan = props.dataItem['누적계획물량']
        const actual = props.dataItem['누적실적물량']

        return (
            <>
                <td>
                    <span>{type}</span>
                    <span className='k-icon k-i-circle' style={{
                        fontSize: '1.5rem', color:
                        actual-plan < -3 ? '#E13D43' :
                        actual-plan >= 0 ? '#00A170' : '#F49B42'
                    }}></span>
                </td>
            </>

        );
    };




    const categorylabes = (e) => {
        if (Mvalue.length >= 4) {
            if (e.index % 3 === 0) {
                return e.value
            } else {
                return ""
            }
        } else {
            return e.value
        }
    }


    const SharedTooltip = (props) => {
        const { points } = props;

        return (
            <div>
                <div>{points[0].category}</div>
                {points.map((point, index) => (
                    <div key={index}>
                        <span>{point.series.name} : {Number(point.value.toFixed(1)).toLocaleString()}%</span>
                    </div>

                ))}
            </div>
        );
    };

    const sharedTooltipRender = (context) => <SharedTooltip {...context} />;


    const [tabledata, settabledata] = useState([]);


    useEffect(() => {
        const fetchData = async () => {

            let body = {
                "bpname": "주요 이슈사항",
                "lineitem": "yes",
            }

            const res = await axios.post(`${process.env.REACT_APP_BASEURL}/getbprecords?path=${project_code}`, body)
            const data = _.sortBy(res.data.data.data.filter(v=> typeof v._bp_lineitems!=='undefined').reduce((c, v) => c.concat(v._bp_lineitems.map(o => Object.assign(o, { record_no: v.record_no, MainIssueDO: moment(o.MainIssueDO.slice(0, 10)).format("YY년 MM월 DD일") }))), []).filter(v => v.MainIssueDiscSPD.includes(checked2)), 'record_no').reverse()
            settabledata(data[0])

        };

        fetchData();
    }, [value, checked2]);


    return (
        <>

            {visible && <Dialog title={"공종 바꾸기"} onClose={toggleDialog} width={350}>

                <RadioGroup data={Discipline} onChange={handleChangeR} value={checked} />
                <DialogActionsBar layout='stretched'>
                    <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={toggleDialog}>취소</button>
                    <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary" onClick={onDeleteData}>변경</button>
                </DialogActionsBar>
            </Dialog>}

            <GridLayout style={{ height: (height - 260) }}
                rows={[
                    {
                        height: '45%',
                    },
                    {
                        height: '12%',
                    },
                    {
                        height: '43%',
                    },
                ]}

                cols={[
                    {
                        width: '65%',
                    }, {
                        width: '35%',
                    },
                ]}
                gap={{
                    rows: 0,
                    cols: 10,
                }}>




                <span style={{ width: '210px', position: 'absolute', right: '12px', zIndex: 999999, top: '4px' }} >
                    <LocalizationProvider language="ko-KR">
                        <IntlProvider locale="ko-KR">
                            <DatePicker min={min} format={"yyyy년 MM월 dd일 EEEE"} onChange={handleChange} value={value} />
                        </IntlProvider>
                    </LocalizationProvider>
                </span>
                <div style={{ width: '400px', position: 'absolute', right: '0px', zIndex: 999999, top: '40px' }} >
                    <span className='k-icon k-i-circle' style={{ fontSize: '1.5rem', color: '#00A170' }}></span><span style={{ fontSize: '0.7rem' }}>on schedule or ahead </span>
                    <span className='k-icon k-i-circle' style={{ fontSize: '1.5rem', color: '#F49B42' }}></span><span style={{ fontSize: '0.7rem' }}>공정율 지연 3% 미만</span>
                    <span className='k-icon k-i-circle' style={{ fontSize: '1.5rem', color: '#E13D43' }}></span><span style={{ fontSize: '0.7rem' }}>공정율 지연 3% 이상</span>
                </div>
                <GridLayoutItem col={1} row={1} style={{ position: 'relative' }}>
                    {/* <ComboBox style={{ width: "150px", zIndex: 999999 }} data={Discipline} value={checked2} defaultValue={checked2 ? checked2 : 'loading'} onChange={handleChangeD} /> */}
                    <div style={{ color: '#363945', fontWeight: 'bolder', fontSize: '1rem' }}>
                        <span style={{ cursor: 'pointer' }} onClick={toggleDialog}><span className='hovertext'>&lt;&nbsp;{checked2 && checked2}&nbsp;&gt;</span> 공정현황 <span className='k-icon k-i-circle'
                            style={{ fontSize: '1.5rem', color: (isNaN(actualpercent_by_disc) ? 0 : actualpercent_by_disc) - planpercent_by_disc < -3 ? '#E13D43' : (isNaN(actualpercent_by_disc) ? 0 : actualpercent_by_disc) - planpercent_by_disc >= 0 ? '#00A170' : '#F49B42' }}
                        ></span></span>
                        <span style={{ fontSize: 'smaller' }}> (계획:{planpercent_by_disc.toFixed(2)}% 실적:{(isNaN(actualpercent_by_disc) ? 0 : actualpercent_by_disc).toFixed(2)}% 차이:{((isNaN(actualpercent_by_disc) ? 0 : actualpercent_by_disc) - planpercent_by_disc).toFixed(2)}%)</span>
                    </div>
                    <Grid
                        data={orderBy(quantity, sort)}
                        sortable={true}
                        sort={sort}
                        onSortChange={(e) => {
                            setSort(e.sort);
                        }}
                        style={{ width: '100%', height: ((height - 260) / 2) - 40 }}
                        className='ptable'
                    >
                        <GridColumn
                            field="Type"
                            title="항목"
                            cell={CustomCellColor}

                        />

                        <GridColumn
                            field="총물량"
                            title="총 가중치"
                            cell={CustomCell2}
                        />

                        <GridColumn
                            title="일일"

                        >

                            <GridColumn
                                field="일계획물량"
                                title="일일 계획공정률"
                                cell={CustomCell}

                            />

                            <GridColumn
                                field="일실적물량"
                                cell={CustomCell}
                                title="일일 실적공정률"
                            />

                            <GridColumn
                                field="일일차이"
                                cell={CustomCell}
                                title="차이"
                            />

                        </GridColumn>

                        <GridColumn
                            title="누적"

                        >
                            <GridColumn
                                field="누적계획물량"
                                cell={CustomCell}
                                title="누계 계획공정률"
                            />

                            <GridColumn
                                field="누적실적물량"
                                cell={CustomCell}
                                title="누계 실적공정률"
                            />


                            <GridColumn
                                field="누적차이"
                                cell={CustomCell}
                                title="차이"
                            />
                        </GridColumn>
                    </Grid>
                    <div style={{ float: 'right' }}>
                        {ddata.find(v => v.DashDiscSPD === checked2) && ddata.find(v => v.DashDiscSPD === checked2)['DashDiscLineMT'] && <div><img src={'/image/check-mark.png'} width={15} style={{ verticalAlign: 'middle' }} alt='check'></img> <span >{ddata.find(v => v.DashDiscSPD === checked2)['DashDiscLineMT']}</span></div>}
                    </div>
                </GridLayoutItem>
                <GridLayoutItem col={2} rowSpan={1} row={1} style={{ position: 'relative' }}>
                    <div style={{ color: '#363945', fontWeight: 'bolder', fontSize: '1rem', height: 25 }}>
                        &nbsp;{checked2 && checked2}&nbsp;주요 이슈 사항
                    </div>

                    <div style={{ overflowY: 'auto', padding: 8, height: ((height - 260) / 2) - 40 }}>
                        {tabledata && tabledata['MainIssueDO'] && tabledata['MainIssueDO']}
                        <div dangerouslySetInnerHTML={{ __html: tabledata && tabledata['MainIssueDesc'] && tabledata['MainIssueDesc'].replaceAll("\\n", '<br/>') }}></div>
                    </div>

                </GridLayoutItem>
                <GridLayoutItem col={1} colSpan={2} row={2} style={{ position: 'relative' }}>
                    {/* <Divider style={{ paddingTop: 50 }} /> */}
                    <span style={{ position: 'absolute', bottom: 0, color: '#363945', fontWeight: 'bolder', fontSize: '1rem' }}>&nbsp;{checked2 && checked2}&nbsp;항목별 공정율</span>   <MultiSelect data={weekTypeList} value={Mvalue} onChange={handleChangeM} style={{ position:'absolute', left:'20%', bottom:0 ,width:'80%'}} /></GridLayoutItem>
                <GridLayoutItem col={1} colSpan={2} row={3} style={{ position: 'relative' }}>
                    <GridLayout style={{ height: (height - 260) * 0.5 }}
                        rows={[
                            {
                                height: '100%',
                            },
                        ]}
                        cols={Mvalue.map((v) => ({ width: (100 / Mvalue.length) + '%' }))}
                        gap={{
                            rows: 0,
                            cols: 0,
                        }}>
                        {Mvalue.map((v, index) => (
                            <GridLayoutItem col={index + 1} row={1} key={index} style={{ position: 'relative' }}>
                                <div style={{ textAlign: 'center' }}>
                                    <span>{v} </span><span className='k-icon k-i-circle' style={{
                                        fontSize: '1.5rem', color: _.get(quantity.filter(f=>f.Type ===v)[0],'누적차이') < -3 ? '#E13D43' :
                                        _.get(quantity.filter(f=>f.Type ===v)[0],'누적차이')  >= 0 ? '#00A170' : '#F49B42'

                                    }}></span>
                                </div>
                                <div style={{ textAlign: 'center', fontSize: '0.8rem' }}>
                                    <span>계획:
                                        {
                                            _.get(quantity.filter(f=>f.Type ===v)[0],'누적계획물량') && (_.get(quantity.filter(f=>f.Type ===v)[0],'누적계획물량')).toFixed(1)
                                        }% </span>

                                    <span>실적:
                                        {
                                           _.get(quantity.filter(f=>f.Type ===v)[0],'누적실적물량') && (_.get(quantity.filter(f=>f.Type ===v)[0],'누적실적물량') ).toFixed(1)
                                        }% </span>

                                    <span>차이:
                                        <span style={{
                                            fontWeight: 'bolder', color:
                                            _.get(quantity.filter(f=>f.Type ===v)[0],'누적차이') >= 0 ? '#34568B' : '#CD212A'

                                        }}>

                                            { _.get(quantity.filter(f=>f.Type ===v)[0],'누적차이') >= 0 ?
                                                <span>	&#40;&#43;&#41;{(_.get(quantity.filter(f=>f.Type ===v)[0],'누적차이')).toFixed(1)}</span>
                                                :
                                                <span>	&#40;&#45;&#41;{Math.abs(_.get(quantity.filter(f=>f.Type ===v)[0],'누적차이')).toFixed(1)}</span>
                                            }%</span></span>
                                </div>

                                <Chart transitions={false} >
                                    {/* <ChartTitle text={`${v}\n계획: 실적: 차이:`} font="0.8rem pretendard-R" /> */}
                                    <ChartArea height={(height - 260) * 0.42} margin={10} />
                                    <ChartLegend position="bottom" orientation="horizontal" labels={{ font: "0.8rem pretendard-R" }} />
                                    <ChartValueAxis>
                                        <ChartValueAxisItem name='월간' labels={{ font: "0.7rem pretendard-R" }} visible={true} majorTicks={{ step: 100, }} minorTicks={{ step: 100, }} majorGridLines={{ step: 10 }} minorGridLines={{ step: 10 }} />
                                        <ChartValueAxisItem name='누적(%)' min={0} max={110} labels={{ font: "0.7rem pretendard-R" }} majorGridLines={{ step: 10 }} minorGridLines={{ step: 10 }} visible={true} />
                                    </ChartValueAxis>
                                    <ChartTooltip shared={true} render={sharedTooltipRender} />
                                    <ChartSeries>

                                        <ChartSeriesItem
                                            color='#DBF3FF'
                                            type="column"
                                            data={weekdata.filter(f => f.Type === v)
                                                .map(({ week, weekPV, weekEV }) =>
                                                ({
                                                    week, weekEV, weekPV,
                                                    weekPV2: weekPV / _.sumBy(dayweight2.filter(f => f.Type === v), 'WeightValue') * 100
                                                }))
                                                // .map((v)=>({...v, C_PV:v.C_PV === null || v.C_PV === undefined ?0:v.C_PV}))
                                            }
                                            field="weekPV2"
                                            categoryField="week"
                                            name="주간 계획"
                                            autoFit={true}
                                            axis='월간'
                                        />


                                        <ChartSeriesItem
                                            color='#42B7F4'
                                            type="column"
                                            data={weekdata.filter(f => f.Type === v)
                                                .map(({ week, weekPV, weekEV }) =>
                                                ({
                                                    week, weekEV, weekPV,
                                                    weekEV2: weekEV / _.sumBy(dayweight2.filter(f => f.Type === v), 'WeightValue') * 100
                                                }))
                                                // .map((v)=>({...v, C_PV:v.C_PV === null || v.C_PV === undefined ?0:v.C_PV}))
                                            }
                                            field="weekEV2"
                                            categoryField="week"
                                            name="주간 실적"
                                            autoFit={true}
                                            axis='월간'

                                        />

                                        <ChartSeriesItem
                                            color='#B1CCE4'
                                            type="line"
                                            data={weekdata.filter(f => f.Type === v
                                                // && Number(f.week.slice(0,2)+f.week.slice(4,6)+f.week.slice(8,9)) <= Number(moment(value).format("YYMM") + Math.ceil((new Date(value)).getDate() / 7) )
                                            ).map(({ week, weekPV, weekEV }) =>
                                            ({
                                                week, weekEV, weekPV,
                                                C_EV: cumev[index] += (isNaN(weekEV) ? 0 : weekEV / _.sumBy(dayweight2.filter(f => f.Type === v), 'WeightValue')) * 100,
                                                C_PV: cumpv[index] += (isNaN(weekPV) ? 0 : weekPV / _.sumBy(dayweight2.filter(f => f.Type === v), 'WeightValue')) * 100
                                            }))
                                                // .map((v)=>({...v, C_PV:v.C_PV === null || v.C_PV === undefined ?0:v.C_PV}))
                                            }
                                            field="C_PV"
                                            categoryField="week"
                                            name="누적 계획"
                                            autoFit={true}
                                            axis='누적(%)'
                                            markers={{ visible: false }}
                                        />

                                        <ChartSeriesItem
                                            color='#5E8EFF'
                                            type="line"
                                            data={weekdata.filter(f => f.Type === v
                                                && Number(f.week.slice(0, 2) + f.week.slice(4, 6) ) <= Number(moment(value).format("YYWW"))
                                            ).map(({ week, weekPV, weekEV }) => ({ week, weekEV, weekPV, C_EV: (cumev[index] += (weekEV)) / _.sumBy(dayweight2.filter(f => f.Type === v), 'WeightValue') * 100, C_PV: cumpv[index] += (weekPV / _.sumBy(dayweight2.filter(f => f.Type === v), 'WeightValue')) * 100 }))}
                                            field="C_EV"
                                            categoryField="week"
                                            name="누적 실적"
                                            autoFit={true}
                                            axis='누적(%)'
                                            markers={{ visible: false }}

                                        />

                                    </ChartSeries>
                                    <ChartCategoryAxis>
                                        <ChartCategoryAxisItem
                                            labels={{ font: "0.7rem pretendard-R", rotation: { angle: 'auto' }, content: categorylabes }}
                                            axisCrossingValue={[0, weekdata.filter(f => f.Type === v).length]}
                                            majorGridLines={{ step: 100 }}
                                            minorGridLines={{ step: 100 }}
                                            plotBands={[{
                                                from:
                                                    _.findIndex(weekdata.filter(f => f.Type === v), { 'week': moment(value).format("YY년 WW주") }),
                                                to: _.findIndex(weekdata.filter(f => f.Type === v), { 'week': moment(value).format("YY년 WW주") }) + 1,
                                                color: 'lightgray',
                                                opacity: 0.35
                                            }]}
                                        >
                                        </ChartCategoryAxisItem>
                                    </ChartCategoryAxis>

                                </Chart>

                            </GridLayoutItem>
                        ))}
                    </GridLayout>


                </GridLayoutItem>

            </GridLayout>
        </>
    );
};

export default ProgressBody

