import React, { useState, useEffect } from 'react';
import { TileLayout } from "@progress/kendo-react-layout";
// import '@progress/kendo-theme-default/dist/all.css';
import useBreakpoint from "use-breakpoint";
import _ from 'lodash';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import '../custom.css'
// import { ComboBox } from "@progress/kendo-react-dropdowns";
import ProgressBody from './progressBody';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { RadioGroup } from '@progress/kendo-react-inputs';

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280, };

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const ProgressDash = () => {

  const { height, width } = useWindowDimensions();
  const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop');
  const { project_code } = useParams();
  const [initial, setinitial] = useState(null);



  const GRID_CONFIG = {
    mobile: [
      {
        col: 1,
        colSpan: 18,
        rowSpan: 6,
        status: true,
      },
    ]
    ,
    tablet: [
      {
        col: 1,
        colSpan: 18,
        rowSpan: 6,
        status: true,
      },
    ],
    desktop: [
      {
        col: 1,
        colSpan: 18,
        rowSpan: 6,
        status: true,
      },
    ]
  }

  const GRIDCONFIG_filterd = { mobile: _.filter(GRID_CONFIG['mobile'], { 'status': true }), tablet: _.filter(GRID_CONFIG['tablet'], { 'status': true }), desktop: _.filter(GRID_CONFIG['desktop'], { 'status': true }) }

  useEffect(() => {
    setPositionData(GRIDCONFIG_filterd[breakpoint])
  }, [breakpoint]);

  const [ddatas, setddata] = useState([]);
  const [AreaName, setAreaName] = useState([]);
  const [ActData, setActData] = useState([]);
  const [value, setValue] = useState(initial);
  const [checked, setChecked] = React.useState(initial && initial.value);
  const [checked2, setChecked2] = React.useState(initial && initial.value);
  const [visible, setVisible] = React.useState(false);

  console.log(AreaName)


  useEffect(() => {
    const fetchData = async () => {

      const res = await axios.get(`${process.env.REACT_APP_BASEURL}/getJSON?path=${process.env.REACT_APP_FILEPATH}/files/Activity2/${project_code}.json`)

      setActData(res.data)

      const res3 = await axios.get(`${process.env.REACT_APP_BASEURL}/getarea?project=${project_code}`)
      setAreaName(res3.data.area)
      setValue(res3.data.area[0])
      setinitial(res3.data.area[0])
      
      const res4 = await axios.get(`${process.env.REACT_APP_BASEURL}/getJSON?path=${process.env.REACT_APP_FILEPATH}/files/conf/${project_code}.json`)

      setddata(res4.data)

    }
    fetchData();
  }, [project_code]);


  const [BoxHeight, setBoxHeight] = useState(250);
  const [positiondata, setPositionData] = useState(GRID_CONFIG['desktop']);

  useEffect(() => {
    const boxHeight = positiondata[2];
    setBoxHeight(((height - 210) / 6) * (boxHeight && boxHeight.rowSpan));
  }, [positiondata, height]);


  const toggleDialog = () => {
    setVisible(!visible);
  };


  const [visibleDialog, setVisibleDialog] = React.useState(false);
  const toggleDialog2 = () => {
    setVisibleDialog(!visibleDialog);
  };

 const [planvalue, setplanvalue] = useState(0);
 const [actualvalue, setactualvalue] = useState(0);


 const selectedArea = checked2 === null ? initial && initial.value : checked2

  const tiles = [{
    header: (<div style={{ position: 'relative'}} >
      {/* {value === null ?initial&&initial.name2:value.name2}  */}

     <span style={{ cursor:'pointer'}} onClick={toggleDialog}> <span>[ {selectedArea} ]</span> 공정현황 </span>
     <span className='k-icon k-i-circle' style={{ fontSize: '1.8rem', color: actualvalue-planvalue <-3 ?'#E13D43':actualvalue-planvalue >= 0?'#00A170' :'#F49B42' }}></span>
     <span style={{fontSize:'smaller'}}>  (계획:{Number(planvalue&&planvalue.toFixed(2)).toLocaleString()}% 실적: {Number(actualvalue&&actualvalue.toFixed(2)).toLocaleString()}% 차이: {Number((actualvalue - planvalue).toFixed(2)).toLocaleString()}%) </span>
     
     &nbsp;<span className='k-icon k-i-photo-camera' onClick={toggleDialog2}  style={{ cursor:'pointer'}}></span>
     </div>),
    body: initial !== null && <ProgressBody AreaName={checked2 === null ? initial : AreaName.filter(v => v.label === checked2)[0]} setplanvalue={setplanvalue} setactualvalue={setactualvalue} ActData={ActData}/>,
    status: true,
    resizable: false,
    reorderable: false,
  },

  ];

  const [tile, settile] = useState(tiles);

  useEffect(() => {
    settile(tiles);
  }, [positiondata, BoxHeight, value, AreaName, initial, checked2, checked,planvalue, actualvalue, ActData]);

  const tile_filtered = _.filter(tile, ['status', true]);


  const handleChangeR = (e) => {
    setChecked(e.value);
  };


  const onDeleteData = () => {
    setChecked2(checked);
    setVisible(!visible);
  };


  return (
    <>

      <TileLayout
        columns={18}
        rowHeight={(height - 210) / 6}
        positions={positiondata}
        gap={{
          rows: 10,
          columns: 10
        }}
        items={tile_filtered}
        autoFlow="row dense"
        className='progress'
        style={{ height: height - 10, width: 'calc(100% - 210)' }}
      />

      {visible && <Dialog title={"Area 바꾸기"} onClose={toggleDialog} width={350}>
        <RadioGroup data={AreaName} onChange={handleChangeR} value={checked} />
        <DialogActionsBar>
          <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={toggleDialog}>취소</button>
          <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary" onClick={onDeleteData}>변경</button>
        </DialogActionsBar>
      </Dialog>}

      {visibleDialog && (
          <Dialog title={"Area 사진"} onClose={toggleDialog2} width={650} height={450}>
            <p dangerouslySetInnerHTML={{ __html:ddatas.filter(v=> selectedArea.includes(v.FloorText) && selectedArea.includes(v.RoomText) )[0]&& ddatas.filter(v=> selectedArea.includes(v.FloorText) && selectedArea.includes(v.RoomText) )[0]['FloorAreaPhotoRT'] }} style={{ width: '100%',}} className='richimag2'></p>
          </Dialog>
   )}
    </>
  )

    ;
};

export default ProgressDash;