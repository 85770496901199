import React, { useEffect, useState } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import axios from 'axios';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useParams } from 'react-router-dom';
import _ from 'lodash';

am4core.useTheme(am4themes_animated);
am4core.addLicense("CH363108325");

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}


function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}


const Qchart = () => {
    const { height, width } = useWindowDimensions();
    const { project_code } = useParams();
    const [piedata, setpiedata] = useState([]);
    const [StageList, setStageList] = useState([]);

    const handleChange = (event) => {
        setState(event.target.value);
    };

    useEffect(() => {
        const fetchData = async () => {
            const res = await axios.get(`${process.env.REACT_APP_BASEURL}/getJSON?path=${process.env.REACT_APP_FILEPATH}/files/Qcharts/${project_code}_chart1.json`)
            setpiedata(res.data)
            setStageList(res.data.map(v => v.IssueStage_txt))
        }
        fetchData();

    }, [project_code]);


    const [state, setState] = React.useState('AFC');


    useEffect(() => {
        /**
     * ---------------------------------------
     * This demo was created using amCharts 4.
     * 
     * For more information visit:
     * https://www.amcharts.com/
     * 
     * Documentation is available at:
     * https://www.amcharts.com/docs/v4/
     * ---------------------------------------
     */

        var container = am4core.create('qchart1', am4core.Container);
        container.width = am4core.percent(100);
        container.height = am4core.percent(100);
        container.layout = "horizontal";


        var chart = container.createChild(am4charts.PieChart);

        chart.numberFormatter.bigNumberPrefixes = [
            { "number": 1e+3, "suffix": "천" },
            { "number": 1e+6, "suffix": "백만" },
            { "number": 1e+8, "suffix": "억" }
          ];
        // Add data
        chart.data = 
        piedata.filter(v => v.IssueStage_txt === state)[0] && piedata.filter(v => v.IssueStage_txt === state)[0].sub


        // Add and configure Series
        var pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "cost";
        pieSeries.dataFields.category = "key";
        pieSeries.slices.template.states.getKey("active").properties.shiftRadius = 0;
        // pieSeries.labels.template.text = "{category} {value}원\n{value.percent.formatNumber('#.#')}%";
        pieSeries.colors.list = ["#0072B5", "#00A170", "#D2386C", "#CD212A", "#FFA500", , "#00758F", "#9C4722"].map(function(color) {
            return new am4core.color(color);
      });

        pieSeries.slices.template.events.on("hit", function (event) {
            selectSlice(event.target.dataItem);
        })

        pieSeries.slices.template.tooltipText = "{category}: {value.formatNumber('#,###.0a')}원";

        var chart2 = container.createChild(am4charts.PieChart);
        chart2.width = am4core.percent(30);
        chart2.radius = am4core.percent(80);

        // Add and configure Series
        var pieSeries2 = chart2.series.push(new am4charts.PieSeries());
        pieSeries2.dataFields.value = "cost";
        pieSeries2.dataFields.category = "key";
        pieSeries2.slices.template.states.getKey("active").properties.shiftRadius = 0;
        //pieSeries2.labels.template.radius = am4core.percent(50);
        //pieSeries2.labels.template.inside = true;
        //pieSeries2.labels.template.fill = am4core.color("#ffffff");
        // pieSeries2.labels.template.disabled = false;
        // pieSeries2.ticks.template.disabled = true;
        // pieSeries2.alignLabels = false;
        pieSeries2.events.on("positionchanged", updateLines);
        // pieSeries2.labels.template.text = "{value.percent.formatNumber('#.#')}%";

        pieSeries2.slices.template.tooltipText = "{category}: {value.formatNumber('#,###.0a')}원";
        pieSeries2.ticks.template.disabled = true;
        pieSeries2.alignLabels = false;
        pieSeries2.labels.template.text = "{value.percent.formatNumber('#.0')}%";
        pieSeries2.labels.template.radius = am4core.percent(-40);
        pieSeries2.labels.template.fill = am4core.color("white");

        pieSeries2.labels.template.adapter.add("radius", function(radius, target) {
            if (target.dataItem.values.value.percent < 10) {
              return am4core.percent(0);
            }
            return radius;
          });
          


        var interfaceColors = new am4core.InterfaceColorSet();

        var line1 = container.createChild(am4core.Line);
        line1.strokeDasharray = "2,2";
        line1.strokeOpacity = 0.5;
        line1.stroke = interfaceColors.getFor("alternativeBackground");
        line1.isMeasured = false;

        var line2 = container.createChild(am4core.Line);
        line2.strokeDasharray = "2,2";
        line2.strokeOpacity = 0.5;
        line2.stroke = interfaceColors.getFor("alternativeBackground");
        line2.isMeasured = false;



        var selectedSlice;

        function selectSlice(dataItem) {

            selectedSlice = dataItem.slice;

            var fill = selectedSlice.fill;

            var count = dataItem.dataContext.sub_sub.length;
            pieSeries2.colors.list = [];
            for (var i = 0; i < count; i++) {
                pieSeries2.colors.list.push(fill.brighten(i * 2 / count));
            }

            chart2.data = dataItem.dataContext.sub_sub;
            pieSeries2.appear();

            var middleAngle = selectedSlice.middleAngle;
            var firstAngle = pieSeries.slices.getIndex(0).startAngle;
            var animation = pieSeries.animate([{ property: "startAngle", to: firstAngle - middleAngle }, { property: "endAngle", to: firstAngle - middleAngle + 360 }], 600, am4core.ease.sinOut);
            animation.events.on("animationprogress", updateLines);

            selectedSlice.events.on("transformed", updateLines);

            //  var animation = chart2.animate({property:"dx", from:-container.pixelWidth / 2, to:0}, 2000, am4core.ease.elasticOut)
            //  animation.events.on("animationprogress", updateLines)
        }



        function updateLines() {
            if (selectedSlice) {
                var p11 = { x: selectedSlice.radius * am4core.math.cos(selectedSlice.startAngle), y: selectedSlice.radius * am4core.math.sin(selectedSlice.startAngle) };
                var p12 = { x: selectedSlice.radius * am4core.math.cos(selectedSlice.startAngle + selectedSlice.arc), y: selectedSlice.radius * am4core.math.sin(selectedSlice.startAngle + selectedSlice.arc) };

                p11 = am4core.utils.spritePointToSvg(p11, selectedSlice);
                p12 = am4core.utils.spritePointToSvg(p12, selectedSlice);

                var p21 = { x: 0, y: -pieSeries2.pixelRadius };
                var p22 = { x: 0, y: pieSeries2.pixelRadius };

                p21 = am4core.utils.spritePointToSvg(p21, pieSeries2);
                p22 = am4core.utils.spritePointToSvg(p22, pieSeries2);

                line1.x1 = p11.x;
                line1.x2 = p21.x;
                line1.y1 = p11.y;
                line1.y2 = p21.y;

                line2.x1 = p12.x;
                line2.x2 = p22.x;
                line2.y1 = p12.y;
                line2.y2 = p22.y;
            }
        }


        chart.events.on("datavalidated", function () {
            setTimeout(function () {
                selectSlice(pieSeries.dataItems.getIndex(0));
            }, 1000);
        });


        return () => {
            container && container.dispose();

        };


    }, [piedata, state]);

    const total = piedata.filter(v => v.IssueStage_txt === state)[0] &&_.sumBy(piedata.filter(v => v.IssueStage_txt === state)[0].sub, 'cost').toLocaleString()

    return (
        <>
            <DropDownList
                style={{
                    width: "100px", float: 'right', fontSize: '0.7rem', height: 20, fontWeight: 600, position: 'absolute', top: 6, right: 6
                }}
                data={StageList}
                value={state}
                onChange={handleChange}
            />
            <div>

        합계 {total}원

            </div>
            <div
                id='qchart1'
                style={{ width: "95%", height: (height - 260) * 0.35, zIndex: '999', margin: '0 auto' ,marginTop:-25, marginLeft:-25}}
            ></div>



        </>
    );
};

export default Qchart