import React, { useState ,useEffect} from 'react';
import axios from "axios";
import moment from 'moment';

function OCR() {
  const [base64String, setBase64String] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [fileExtension, setFileExtension] = useState('');

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'];
    const maxSize = 10 * 1024 * 1024; // 10MB

  // 파일 확장자 추출
    const ext = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);
    setFileExtension(ext);
    // 파일 타입 확인
    if (!validTypes.includes(file.type)) {
      setErrorMessage('허용되지 않는 파일 형식입니다. 이미지나 PDF 파일만 업로드 가능합니다.');
      return;
    }

    // 파일 크기 확인
    if (file.size > maxSize) {
      setErrorMessage('파일 크기가 너무 큽니다. 10MB 이하의 파일만 업로드 가능합니다.');
      return;
    }

    const reader = new FileReader();

    reader.onload = (loadEvent) => {
      const base64 = loadEvent.target.result;
      setBase64String(base64);
      setErrorMessage(''); // 오류 메시지 초기화
    };

    reader.readAsDataURL(file);
  };


    useEffect(() => {
        const fetchData = async () => {
            const body = {
                fileExtension:fileExtension,
                base64String:base64String
            }
            if (base64String){
            const res = await axios.post(`${process.env.REACT_APP_BASEURL}/getocr`, body)
            console.log(res.data)
            }
        };

    fetchData();

    }, [ fileExtension, base64String]);

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
      {base64String && (
        <div>
          <p>Base64:</p>
          {/* <textarea value={base64String} readOnly rows="10" cols="50" /> */}
        </div>
      )}
    </div>
  );
}

export default OCR;
