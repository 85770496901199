import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { filterBy, process } from "@progress/kendo-data-query";
import axios from 'axios';
import { ColumnMenu } from "./columnMenu";
import { CustomColumnMenu } from './customColumnMenu';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import {
  setExpandedState,
  setGroupIds
} from "@progress/kendo-react-data-tools";
import { ExcelExport } from '@progress/kendo-react-excel-export';
import '@progress/kendo-theme-default/dist/all.css';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}


function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}


const QunatityTable = () => {

  const { height, width } = useWindowDimensions();
  const [dashdata, setdashdata] = React.useState([]);
  const [projects, setProjects] = React.useState([]);

  const [issuestage, setStage] = React.useState("");
  const [revison, setRevision] = React.useState("");
  const [cpms, setCPMS] = React.useState([]);

  const [projects2, setProjects2] = React.useState(_.uniqBy(dashdata, 'project_projectname').map(v => v.project_projectname)[0]);

  const [issuestage2, setStage2] = React.useState("");
  const [revison2, setRevision2] = React.useState("");
  const [cpms2, setCPMS2] = React.useState([]);

  const [projectState, setprojectState] = React.useState('');
  const [issuestageState, setissuestageState] = React.useState('');
  const [revisonState, setrevisonState] = React.useState('');
  const [cpmsState, setcpmsState] = React.useState('');
  const [projectState2, setprojectState2] = React.useState('');
  const [issuestageState2, setissuestageState2] = React.useState('');
  const [revisonState2, setrevisonState2] = React.useState('');
  const [cpmsState2, setcpmsState2] = React.useState('');



  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`${process.env.REACT_APP_BASEURL}/getJSON?path=${process.env.REACT_APP_FILEPATH}/files/Qcharts/quantity.json`)
      setdashdata(res.data)
      if (res.data.length > 0) {
        const initialProjects = _.uniqBy(res.data, 'project_projectname').map(v => v.project_projectname);
        setprojectState(initialProjects[0]);
        setprojectState2(initialProjects[1]);
      }
      
    }
    fetchData();

  }, []);

  const handleChangeP1 = (event) => {
    setprojectState(event.target.value);
  };


  const handleChangeI1 = (event) => {
    setissuestageState(event.target.value);
  };

  const handleChangeR1 = (event) => {
    setrevisonState(event.target.value);
  };


  const handleChangeC1 = (event) => {
    setcpmsState(event.target.value);
  };

  const handleChangeP2 = (event) => {
    setprojectState2(event.target.value);
  };


  const handleChangeI2 = (event) => {
    setissuestageState2(event.target.value);
  };

  const handleChangeR2 = (event) => {
    setrevisonState2(event.target.value);
  };


  const handleChangeC2 = (event) => {
    setcpmsState2(event.target.value);
  };




  useEffect(() => {
    setProjects(_.uniqBy(dashdata, 'project_projectname').map(v => v.project_projectname))
    setProjects2(_.uniqBy(dashdata, 'project_projectname').map(v => v.project_projectname))
  }, [dashdata]);


  useEffect(() => {
    setStage(_.uniqBy(dashdata.filter(v => v.project_projectname === projectState), 'IssueStage_txt').map(v => v.IssueStage_txt).filter(v => v !== null))
    setissuestageState(_.uniqBy(dashdata.filter(v => v.project_projectname === projectState), 'IssueStage_txt').map(v => v.IssueStage_txt).filter(v => v !== null)[0])
  }, [dashdata, projectState]);


  useEffect(() => {
    setRevision(
      _.uniqBy(
        dashdata.filter(v => projectState === v.project_projectname && issuestageState === v.IssueStage_txt), 'GenRevision'
      )
        .map(v => v.GenRevision).filter(v => v !== null))
        setrevisonState(
          _.uniqBy(
            dashdata.filter(v => projectState === v.project_projectname && issuestageState === v.IssueStage_txt), 'GenRevision'
          )
            .map(v => v.GenRevision).filter(v => v !== null)[0]
        )
  }, [dashdata, projectState, issuestageState]);

  useEffect(() => {
    setCPMS(_.uniqBy(dashdata.filter(v => projectState === v.project_projectname && issuestageState === v.IssueStage_txt && revisonState === v.GenRevision), 'CPMSVer_dpk')
      .map(v => v.CPMSVer_dpk).filter(v => v !== null))

      setcpmsState(
        _.uniqBy(dashdata.filter(v => projectState === v.project_projectname && issuestageState === v.IssueStage_txt && revisonState === v.GenRevision), 'CPMSVer_dpk')
      .map(v => v.CPMSVer_dpk).filter(v => v !== null)[0]
      )
  }, [dashdata, projectState, issuestageState, revisonState]);


  /////////////
  useEffect(() => {
    setStage2(
      _.uniqBy(dashdata.filter(v => v.project_projectname === projectState2), 'IssueStage_txt').map(v => v.IssueStage_txt).filter(v => v !== null)
      )
    setissuestageState2(
      _.uniqBy(dashdata.filter(v => v.project_projectname === projectState2), 'IssueStage_txt').map(v => v.IssueStage_txt).filter(v => v !== null)[0]
    )
  }, [dashdata, projectState2]);


  useEffect(() => {
    setRevision2(
      _.uniqBy(
        dashdata.filter(v => projectState2 === v.project_projectname && issuestageState2 === v.IssueStage_txt), 'GenRevision'
      )
        .map(v => v.GenRevision).filter(v => v !== null))
        setrevisonState2(
          _.uniqBy(
            dashdata.filter(v => projectState2 === v.project_projectname && issuestageState2 === v.IssueStage_txt), 'GenRevision'
          )
            .map(v => v.GenRevision).filter(v => v !== null)[0]
        )
  }, [dashdata, projectState2, issuestageState2]);

  useEffect(() => {
    setCPMS2(_.uniqBy(dashdata.filter(v => projectState2 === v.project_projectname && issuestageState2 === v.IssueStage_txt && revisonState2 === v.GenRevision), 'CPMSVer_dpk')
      .map(v => v.CPMSVer_dpk).filter(v => v !== null))
      setcpmsState2(_.uniqBy(dashdata.filter(v => projectState2 === v.project_projectname && issuestageState2 === v.IssueStage_txt && revisonState2 === v.GenRevision), 'CPMSVer_dpk')
      .map(v => v.CPMSVer_dpk).filter(v => v !== null)[0])
  }, [dashdata, projectState2, issuestageState2, revisonState2]);


  ////////테이블 데이터 정리



  const tabledata1 =   dashdata
  .filter(v =>
    (v.project_projectname === projectState
      && v.IssueStage_txt === issuestageState
      && v.GenRevision === revisonState
      && v.CPMSVer_dpk === cpmsState) )
    .map((v)=>({...v, uniq:v.공종+ v.room+v.spocMDisc+v.item}))
    .map(obj => {
      let newObj = { ...obj }; // 원본 객체를 변경하지 않도록 새 객체를 생성
      newObj["value1"] = newObj["value"]; // 이름 변경
      newObj["valuerate1"] = newObj["valuerate"]; // 이름 변경
      return newObj;
  });


  const tabledata2 = 
  dashdata
  .filter(v =>
    (v.project_projectname === projectState2
      && v.IssueStage_txt === issuestageState2
      && v.GenRevision === revisonState2
      && v.CPMSVer_dpk === cpmsState2)
  ).map((v)=>({...v, uniq:v.공종+ v.room+v.spocMDisc+v.item}))
  .map(obj => {
    let newObj = { ...obj }; // 원본 객체를 변경하지 않도록 새 객체를 생성
    newObj["value2"] = newObj["value"]; // 이름 변경
    newObj["valuerate2"] = newObj["valuerate"]; // 이름 변경
    return newObj;
});


const tabledata = 
_.uniqBy([...tabledata1, ...tabledata2], 'uniq')
.map((v)=>({...v, 
  value1:tabledata1.find(vf=>vf.uniq === v.uniq)&& tabledata1.find(vf=>vf.uniq === v.uniq)['value1'],
  valuerate1:tabledata1.find(vf=>vf.uniq === v.uniq)&& tabledata1.find(vf=>vf.uniq === v.uniq)['valuerate1'],
  value2:tabledata2.find(vf=>vf.uniq === v.uniq)&& tabledata2.find(vf=>vf.uniq === v.uniq)['value2'],
  valuerate2:tabledata2.find(vf=>vf.uniq === v.uniq)&& tabledata2.find(vf=>vf.uniq === v.uniq)['valuerate2'],
}))


  const _export = useRef(null);

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };


  const percentCell = (props) => {

    const field = props.field || "";
    const value = props.dataItem[field];

    return (
      <td colSpan={props.colSpan} style={props.style}>
        {(value ? (value*100).toFixed(1)+'%' : '')}
      </td>
    );
  };


  const numberCell = (props) => {

    const field = props.field || "";
    const value = props.dataItem[field];

    return (
      <td colSpan={props.colSpan} style={props.style}>
        {(value ? Number((value).toFixed(0)).toLocaleString() : '')}
      </td>
    );
  };

  return (
    <>
          <div style={{ display: 'flex' }}>
        <div style={{ margin: 12 }}>
          <div style={{ fontSize: '0.8rem' }}>
            첫번째 프로젝트: {cpmsState ? projectState + " " + issuestageState + " " + revisonState + " " + cpmsState : ''}
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: 8 }}>
              <div style={{ verticalAlign: 'middle', fontSize: '0.6rem' }}>Project: </div>
              <DropDownList
                style={{
                  width: "180px", fontSize: '0.8rem', height: 28, fontWeight: 600,
                }}
                data={projects}
                value={projectState}
                onChange={handleChangeP1}
              />
            </div>

            {issuestage && (
              <div style={{ marginRight: 8 }}>
                <div style={{ verticalAlign: 'middle', fontSize: '0.6rem' }}>Issue Stage: </div>
                <DropDownList
                  style={{
                    width: "180px", fontSize: '0.8rem', height: 30, fontWeight: 600,
                  }}
                  data={issuestage}
                  value={issuestageState}
                  onChange={handleChangeI1}
                />
              </div>
            )}


            {revison && (
              <div style={{ marginRight: 8 }}>
                <div style={{ verticalAlign: 'middle', fontSize: '0.6rem' }}>Revision: </div>
                <DropDownList
                  style={{
                    width: "180px", fontSize: '0.8rem', height: 30, fontWeight: 600,
                  }}
                  data={revison}
                  value={revisonState}
                  onChange={handleChangeR1}
                />
              </div>

            )}

            {cpms && (
              <div style={{ marginRight: 8 }}>
                <div style={{ verticalAlign: 'middle', fontSize: '0.6rem' }}>CPMS Version: </div>
                <DropDownList
                  style={{
                    width: "180px", fontSize: '0.8rem', height: 30, fontWeight: 600,
                  }}
                  data={cpms}
                  value={cpmsState}
                  onChange={handleChangeC1}
                />
              </div>
            )}

          </div>
        </div>

        <div style={{ margin: 12 }}>
          <div style={{ fontSize: '0.8rem' }}>
            두번째 프로젝트: {cpmsState2 ? projectState2 + " " + issuestageState2 + " " + revisonState2 + " " + cpmsState2 : ''}
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: 8 }}>
              <div style={{ verticalAlign: 'middle', fontSize: '0.6rem' }}>Project: </div>
              <DropDownList
                style={{
                  width: "180px", fontSize: '0.8rem', height: 28, fontWeight: 600,
                }}
                data={projects2}
                value={projectState2}
                onChange={handleChangeP2}
              />
            </div>

            {issuestage2 && (
              <div style={{ marginRight: 8 }}>
                <div style={{ verticalAlign: 'middle', fontSize: '0.6rem' }}>Issue Stage: </div>
                <DropDownList
                  style={{
                    width: "180px", fontSize: '0.8rem', height: 30, fontWeight: 600,
                  }}
                  data={issuestage2}
                  value={issuestageState2}
                  onChange={handleChangeI2}
                />
              </div>
            )}


            {revison2 && (
              <div style={{ marginRight: 8 }}>
                <div style={{ verticalAlign: 'middle', fontSize: '0.6rem' }}>Revision: </div>
                <DropDownList
                  style={{
                    width: "180px", fontSize: '0.8rem', height: 30, fontWeight: 600,
                  }}
                  data={revison2}
                  value={revisonState2}
                  onChange={handleChangeR2}
                />
              </div>

            )}

            {cpms2 && (
              <div style={{ marginRight: 8 }}>
                <div style={{ verticalAlign: 'middle', fontSize: '0.6rem' }}>CPMS Version: </div>
                <DropDownList
                  style={{
                    width: "180px", fontSize: '0.8rem', height: 30, fontWeight: 600,
                  }}
                  data={cpms2}
                  value={cpmsState2}
                  onChange={handleChangeC2}
                />
              </div>
            )}

          </div>
        </div>
      </div>

      <ExcelExport data={tabledata} ref={_export}>

        <Grid
          style={{
            height: (height - 260), marginTop: 10, marginLeft: 10
          }}
          data={tabledata}
          className='qcost'
          size={'small'}

        >
          <GridToolbar>
            {tabledata.length>0?
            <button
              title="Export Excel"
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
              onClick={excelExport}
              style={{ position: 'absolute', right: '5px' }}
            >
              <span className="k-icon k-i-file-excel"></span>
            </button>
            :""
            }
          </GridToolbar>
          <Column field="공종" title="구분" width={80} />
          <Column field="room" title="Room Lev1" width={150} />
          <Column field="spocMDisc" title="중공종" width={250} />

 
              <Column field='item' title='항목' width={200}  />
              <Column field='uom' title='단위' width={80}  />
 

          {cpmsState &&
            <Column title={projectState}  >
              <Column field={'value1'} title='합계 물량' width={120} cell={numberCell} />
              <Column field={'valuerate1'} title='비중' width={80} cell={percentCell}/>
            </Column>
          }

          {cpmsState2 &&
            <Column title={projectState2} >
              <Column field={'value2'} title='합계 물량' width={120}  cell={numberCell}/>
              <Column field={'valuerate2'} title='비중' width={80} cell={percentCell} />
            </Column>
          }

        </Grid>
      </ExcelExport>

    </>
  );
};

export default QunatityTable