import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import {
    GridLayout,
    GridLayoutItem,
} from "@progress/kendo-react-layout";
import moment from 'moment';
import { Chart, ChartCategoryAxisItem, ChartCategoryAxis, ChartArea, ChartLegend, ChartSeries, ChartSeriesItem, ChartValueAxis, ChartValueAxisItem, ChartTooltip, exportVisual, ChartTitle } from "@progress/kendo-react-charts";
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import { exportImage } from "@progress/kendo-drawing";
import { saveAs } from "@progress/kendo-file-saver";
import 'hammerjs';
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { DropDownTree } from '@progress/kendo-react-dropdowns';
import { processTreeData, expandedState } from './tree-data-opreation';
import axios from "axios";
import { Tooltip } from "@progress/kendo-react-tooltip";
import MuiToggleButton from '@mui/material/ToggleButton';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import * as XLSX from 'xlsx';

const ToggleButton = styled(MuiToggleButton)(() => ({
    '&.Mui-selected, &.Mui-selected:hover': {
        color: 'white',
        backgroundColor: '#4B5335',
    },
    '&.MuiToggleButtonGroup-grouped': {
        color: 'white',
        // backgroundColor: '#4B5335',
    },
    '&.MuiToggleButton-root': {
        fontSize: '0.7rem',
        fontFamily: 'Pretendard-R',
        padding: 0,
        width: 75,
        borderRadius: '5px 5px 5px 5px!important',
        border: '1px solid rgba(0, 0, 0, 0.12)!important',
        // color: 'white',
        // backgroundColor: '#4B5335',


    }
}));

const ToggleButtonGroup = styled(MuiToggleButtonGroup)(() => ({
    '&.MuiToggleButtonGroup-root': {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1)
        }
    }
}));

const theme = createTheme({
    palette: {
        text: {
            primary: '#00ff00',
        },
    },
});

const selectField = "selected";
const expandField = "expanded";
const dataItemKey = "text";
const textField = "text";
const subItemsField = "items";
const fields = {
    selectField,
    expandField,
    dataItemKey,
    subItemsField,
};


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}


function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}


const Progress2 = ({datevalue}) => {
    const { height, width } = useWindowDimensions();

    const [selectedValue, setSelectedValue] = useState("monthly");
    const [data2, setdata2] = useState([]);
    const { project_code } = useParams();

    const [value_area, setValue2] = useState({ text: '' });
    const [value, setValue] = useState({ text: '' });
    const [value3, setValue3] = useState(null);


    const today = moment(new Date()).format("YYYY-MM-DD");


    const handleChange2 = (event, selectedValue) => {
        if (selectedValue !== null) {
            setSelectedValue(selectedValue);
        }
    };


    const handleChange3 = (event) => {
        setValue3(event.target.value);
    };

    const selectdata = data2 &&data2.selectdata
    const selectdata2 = data2 &&data2.selectdata2
    const selectdata3 = data2 &&data2.selectdata3

    
    var dataarea = selectdata && selectdata, keys = ['Area'], gresult = [], temp = { _: gresult };
    dataarea && dataarea.forEach(function (a) {
        keys.reduce(function (r, k) {
            if (!r[a[k]]) {
                r[a[k]] = { _: [] };
                r._.push({ text: a['Area'], items: r[a[k]]._ });
            }
            return r[a[k]];
        }, temp)._.push({ text:a.Subarea });
    });

    var datad = selectdata2 && selectdata2, keys = ['Discipline'], gresult2 = [], temp = { _: gresult2 };
    datad && datad.forEach(function (a) {
        keys.reduce(function (r, k) {
            if (!r[a[k]]) {
                r[a[k]] = { _: [] };
                r._.push({ text: a['Discipline'], items: r[a[k]]._ });
            }
            return r[a[k]];
        }, temp)._.push({ text: a.SubDiscipline });
    });


    const [expanded, setExpanded] = useState(['']);

    const [filter, setFilter] = React.useState({
        value: "",
        operator: "contains",
    });

    const onChange = (event) => setValue2(event.value);
    const onChange2 = (event) => setValue(event.value);

    const onExpandChange = React.useCallback(
        (event) => setExpanded(expandedState(event.item, dataItemKey, expanded)),
        [expanded]
    );


    const treeData = React.useMemo(
        () =>
            processTreeData(
                gresult,
                {
                    expanded,
                    value_area,
                    filter
                },
                fields
            ),
        [expanded, value_area, gresult, filter]
    );


    const treeData2 = React.useMemo(
        () =>
            processTreeData(
                gresult2,
                {
                    expanded,
                    value,
                    filter
                },
                fields
            ),
        [expanded, value, gresult2, filter]
    );


    const onFilterChange = (event) => setFilter(event.filter);



    useEffect(() => {
        const fetchData = async () => {

            const res = await axios.get(`${process.env.REACT_APP_BASEURL}/progresscontrol1?project=${project_code}&value_area=${value_area === null ? 'deselect':value_area.text }&value=${value === null ? 'deselect':value.text}&value3=${value3}&datevalue=${datevalue}`)
            setdata2(res.data)

        };

        fetchData();

    }, [ project_code, value3, value_area, value,datevalue]);


    const result_last = selectedValue === 'monthly' ? 
    data2&&data2.month : 
    data2&&data2.week


    const SharedTooltip = (props) => {
        const { points } = props;


        return (
            <div>

            {  
            
            selectedValue === 'monthly' ?
            
            Number(points[0].category.slice(0,2)+points[0].category.slice(4,6)) <= Number(moment().format('YYMM')) ?
                <>
                <div>{points[0].category}</div>
                {points.filter(v=>v.series.name !=='').map((point, index) => (
                    <div key={index}>
                            <span>{point.series.name} : {Number(point.value.toFixed(1)).toLocaleString()}% </span> 
                
                    </div>

                ))} 
                </>
                :
                <>

                <div>{points[0].category}</div>
                {points.map((point, index) => (
                    <div key={index}>
                            <span>{point.series.name === ''? '누적 계획':point.series.name} : {Number(point.value.toFixed(1)).toLocaleString()}% </span> 
                
                    </div>

                ))}
                </> 

                :
                Number(points[0].category.slice(0,2)+points[0].category.slice(4,6)+points[0].category.slice(8,11)) <= Number(moment().format('YYMMWW')) ?
                <>
                <div>{points[0].category}</div>
                {points.filter(v=>v.series.name !=='').map((point, index) => (
                    <div key={index}>
                            <span>{point.series.name} : {Number(point.value.toFixed(1)).toLocaleString()}%</span> 
                
                    </div>

                ))} 
                </>
                :
                <>

                <div>{points[0].category}</div>
                {points.map((point, index) => (
                    <div key={index}>
                            <span>{point.series.name === ''? '누적 계획':point.series.name} : {Number(point.value.toFixed(1)).toLocaleString()}%</span> 
                    </div>

                ))}
                </>
                    
                    
                }


            </div>
        );
    };

    const sharedTooltipRender = (context) => <SharedTooltip {...context} />;


    const labelContentAxis = (e) => e.value > 10000 ? (e.value / 10000).toFixed(0) + '만' : e.value;
    const labelContentAxis1 = (e) => e.value > 100 ? '' : e.value + '%';

    const [zoomableyn, setzoomableyn] = useState(false);

    const toggleZoom = () => {
        setzoomableyn(!zoomableyn);
    };

    const chart = useRef(null);

    const onImageExportClick = () => {
        const chartVisual = exportVisual(chart.current);

        if (chartVisual) {
            exportImage(chartVisual).then((dataURI) => saveAs(dataURI, "SCurvechart.png"));
        }
    };


    const axisCrossingValue = result_last && [0, result_last.length];


    const charttitle =
        (value_area === null || value_area.text === '') && (value === null || value.text === '') && value3 === null?
            '시공 S-Curve (ALL)' :
            value_area !== null && value === null&& value3 === null ?
                `시공 S-Curve (Area: ${value_area.text})` :
                value_area === null && value !== null && value3 === null?
                    `시공 S-Curve (공종: ${value.text})` :
                value_area === null && value === null && value3 !== null?
                    `시공 S-Curve (성상: ${value3} )`:
                value_area === null && value !== null && value3 !== null?
                `시공 S-Curve (공종: ${value.text} / 성상: ${value3} )`:
                value_area !== null && value === null && value3 !== null?
                `시공 S-Curve (Area: ${value_area.text} / 성상: ${value3} )`:
                value_area !== null && value !== null && value3 === null?
                `시공 S-Curve (Area: ${value_area.text} / 공종: ${value.text} )`:
                    `시공 S-Curve (Area: ${value_area.text} / 공종: ${value.text} / 성상: ${value3})`


    const handleChange = (event) => {
        setValue(event.target.value);
    };


    const last_month = moment().format("YYMM");
    const last_week = 
    moment().format("YYWW") 
    // moment().format("YYMM") + Math.ceil((new Date()).getDate() / 7);
    
    const planline1 = selectedValue === 'monthly' ? result_last&&result_last.filter(v => Number(v.date.slice(0, 2) + v.date.slice(4, 6)) <= Number(last_month)) :  result_last&&result_last.filter(v => Number(v.date.slice(0, 2) + v.date.slice(4, 6) ) <= Number(last_week))
    const planline2 = selectedValue === 'monthly' ?  result_last&&result_last.filter(v => Number(v.date.slice(0, 2) + v.date.slice(4, 6)) >= Number(last_month)) :  result_last&&result_last.filter(v => Number(v.date.slice(0, 2) + v.date.slice(4, 6)) >= Number(last_week))


    const planpercent = data2&&data2.planpercent


    const actualpercent = data2&&data2.actualpercent



    const categorylabes = (e) => {
        if(selectedValue === 'weekly'){
            if(e.index%4 ===0){

                    return e.value
            } else {
                return ""
            }
        } 
        
        else {
        return e.value
        }
    }


    const excelfilename = `${selectedValue} ${charttitle}.xlsx`

    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(result_last.map((v)=>({'기준월':v.date, '계획':v.PV2,'실적':v.EV2,  '누적계획':v.CC_PV, '누적실적':v.CC_EV })));
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, excelfilename);
      };

    const tooltiptitle = `${moment(datevalue).format('YYYY년 MM월 DD일')}까지 일할 계산된 계획값입니다.`
    const tooltiptitle2 = `실적은 최신 실적(주로 전일 작업일보)을 한시간마다 집계합니다.`

    return (
        <>
            <GridLayout style={{ height: (height - 260) * 2 / 3 }}
                rows={[

                    {
                        height: '18%',
                    },

                    {
                        height: '82%',
                    },


                ]}

                cols={[
                    {
                        width: '25%',
                    }, {
                        width: '75%',
                    },


                ]}
                gap={{
                    rows: 0,
                    cols: 0,
                }}>

                <div style={{ position: 'absolute', top: '16px', right: '12px', transform: 'translateY(-50%)', zIndex: '99999999' }} className='manRB'>
                    <ThemeProvider theme={theme}>
                        <ToggleButtonGroup size="small" onChange={handleChange2} value={selectedValue} exclusive aria-label="baseunit">

                            <ToggleButton value="weekly" aria-label="weekly">
                                weekly
                            </ToggleButton>
                            <ToggleButton value="monthly" aria-label="monthly">
                                monthly
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </ThemeProvider>

                </div>

                <GridLayoutItem col={1} colSpan={2} row={1} style={{ position: 'relative' }}>
                    <div style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', width: '100%' }}>
                        <div style={{ textAlign: 'center', float: 'left', width: '25%' }}>
                            <div style={{ fontSize: '0.8rem' }}>Total</div> <div style={{ fontSize: '1.2rem', fontWeight: 'bolder' }}>{data2 && (data2.c_count + data2.n_count + data2.i_count)}</div>
                        </div>
                        <div style={{ textAlign: 'center', float: 'left', width: '25%' }}>
                            <div style={{ fontSize: '0.8rem' }}>완료</div> <div style={{ fontSize: '1.2rem', fontWeight: 'bolder' }}>{data2&&data2.c_count}</div>
                        </div>
                        <div style={{ textAlign: 'center', float: 'left', width: '25%' }}>
                            <div style={{ fontSize: '0.8rem' }}>시작안함</div> <div style={{ fontSize: '1.2rem', fontWeight: 'bolder' }}>{data2&&data2.n_count}</div>
                        </div>
                        <div style={{ textAlign: 'center', float: 'left', width: '25%' }}>
                            <div style={{ fontSize: '0.8rem' }}>진행중</div> <div style={{ fontSize: '1.2rem', fontWeight: 'bolder' }}>{data2&&data2.i_count}</div>
                        </div>
                    </div>
                </GridLayoutItem>

                <GridLayoutItem col={1} row={2}>
                    <div style={{ width: '100%', height: (height - 310) * 2 / 3 * 0.5 }}>
                        <div style={{ position: 'relative' }}>
                            <div style={{ position: 'absolute', top: '50px', left: '50%', transform: 'translateX(-50%)' }}>
                                <div style={{ textAlign: 'center', color: '#363945' }}>Dev.</div>

                                <div style={{ textAlign: 'center', fontSize: '2rem', fontWeight: 'bold' }}>

                                        <span style={{ color: actualpercent - planpercent > 0 ?'#00539C':'#9E1030' }}>{(actualpercent - planpercent).toFixed(2)}</span>%</div>
                            </div>
                            <Tooltip anchorElement="target" position="top">
                                <div style={{ position: 'absolute', top: '140px', left: '8px' }} title={tooltiptitle}>

                                    <div style={{ color: '#939597', fontSize: '1rem', float: 'left' }}>Plan</div>

                                    <div style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>  {planpercent && planpercent.toFixed(2)}%</div>
                                </div>
                            </Tooltip>
                            <Tooltip anchorElement="target" position="top">
                            <div style={{ position: 'absolute', top: '140px', right: '8px' }} title={tooltiptitle2}>
                                <div style={{ textAlign: 'center', color: '#939597', fontSize: '1rem' }}>Actual</div>
                                <div style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold', color: 'black' }}>{actualpercent && actualpercent.toFixed(2)}%</div>
                            </div>
                            </Tooltip>
                    {/* <div style={{ position: 'absolute', top:'180px',left: '50%', transform: 'translateX(-50%)' ,fontSize:'0.65rem'}}>{moment().add(-1, 'days').format('YYYY년 MM월 DD일')} 기준 </div> */}

                        </div>
                    </div>


                    <div>
                        <div style={{ marginLeft: '5px' }}>Area</div>
                        <DropDownTree
                            style={{
                                width: "100%", marginRight: '5px'
                            }}
                            data={treeData}
                            value={value_area}
                            onChange={onChange}
                            // placeholder="Area를 고르세요..."
                            textField={textField}
                            dataItemKey={dataItemKey}
                            selectField={selectField}
                            expandField={expandField}
                            onExpandChange={onExpandChange}
                            filterable={true}
                            onFilterChange={onFilterChange}
                            filter={filter.value}
                        />
                    </div>

                    <div>
                        <div style={{ marginLeft: '5px' }}>공종</div>

                        <DropDownTree
                            style={{
                                width: "100%", marginRight: '5px'
                            }}
                            data={treeData2}
                            value={value}
                            onChange={onChange2}
                            // placeholder="공종을 고르세요..."
                            textField={textField}
                            dataItemKey={dataItemKey}
                            selectField={selectField}
                            expandField={expandField}
                            onExpandChange={onExpandChange}
                            filterable={true}
                            onFilterChange={onFilterChange}
                            filter={filter.value}
                        />
                    </div>
                    <div>
                        <div style={{ marginLeft: '5px' }}>성상</div>
                        <ComboBox
                            style={{
                                width: "100%", marginRight: '5px'
                            }}
                            data={selectdata3}
                            value={value3}
                            onChange={handleChange3}
                            className='disc'
                        />
                    </div>
                </GridLayoutItem>


                <GridLayoutItem col={2} row={2}>

                    <div style={{ position: 'absolute', top: '140px', right: '12px', zIndex: 999999999 }}>
                        {/* <ButtonGroup> */}
                        <Button
                            title="확대/이동 켜기"
                            iconClass='k-icon k-i-zoom-in'
                            themeColor={zoomableyn ? "primary" : null} fillMode="flat"
                            onClick={toggleZoom}
                        >
                        </Button>

                        <Button
                            title="이미지로 저장"
                            iconClass='k-icon k-i-image-export'
                            fillMode="flat"
                            onClick={onImageExportClick}
                        >
                        </Button>

                        <Button
                            title="엑셀로 저장"
                            iconClass='k-icon k-i-xls'
                            fillMode="flat"
                            onClick={downloadExcel}
                        >
                        </Button>


                        {/* </ButtonGroup> */}
                    </div>


                    <Chart ref={chart} pannable={zoomableyn} zoomable={zoomableyn}>
                        <ChartTitle text={charttitle} font="0.8rem pretendard-R" />
                        <ChartArea height={(height - 310) * 2 / 3 * 0.9} margin={5} />
                        <ChartLegend position="bottom" orientation="horizontal" />
                        <ChartValueAxis>
                            <ChartValueAxisItem name='월간' min={0}  labels={{ content: labelContentAxis1, font: "0.7rem Arial, pretendard-R" }} visible={true} majorGridLines={{ step: 10 }} minorGridLines={{ step: 10 }} />
                            <ChartValueAxisItem name='누적(%)' min={0} max={110} labels={{ content: labelContentAxis1, font: "0.7rem Arial, pretendard-R" }} majorGridLines={{ step: 10 }} minorGridLines={{ step: 10 }} visible={true} />
                        </ChartValueAxis>
                        <ChartTooltip shared={true} render={sharedTooltipRender} />
                        <ChartSeries>

                            <ChartSeriesItem
                                color='#DBF3FF'
                                type="column"
                                data={result_last}
                                field="PV2"
                                categoryField="date"
                                name={selectedValue === 'monthly'? '월간 계획':'주간 계획'}
                                autoFit={true}
                                axis='월간'
                            />

                            <ChartSeriesItem
                                color='#DBF3FF'
                                type="column"
                                data={result_last}
                                field="PV"
                                categoryField="date"
                                autoFit={true}
                                axis='월간'
                                visible={false}
                            />

                            <ChartSeriesItem
                                color='#DBF3FF'
                                type="column"
                                data={result_last}
                                field="EV2"
                                categoryField="date"
                                autoFit={true}
                                axis='월간'
                                visible={false}
                            />


                            <ChartSeriesItem
                                color='#42B7F4'
                                type="column"
                                data={result_last}
                                field="EV2"
                                categoryField="date"
                                name={selectedValue === 'monthly'? '월간 실적':'주간 실적'}
                                autoFit={true}
                                axis='월간'

                            />

                            <ChartSeriesItem
                                color='#B1CCE4'
                                type="line"
                                data={planline1}
                                field="CC_PV"
                                categoryField="date"
                                name="누적 계획"
                                autoFit={true}
                                axis='누적(%)'
                            />
                            <ChartSeriesItem
                                color='#B1CCE4'
                                type="line"
                                data={planline2}
                                field="CC_PV"
                                categoryField="date"
                                name=""
                                autoFit={true}
                                axis='누적(%)'
                                dashType='dash'
                            />


                            <ChartSeriesItem
                                color='#5E8EFF'
                                type="line"
                                data={result_last}
                                field="CC_EV"
                                categoryField="date"
                                name="누적 실적"
                                autoFit={true}
                                axis='누적(%)'
                            />

                        </ChartSeries>
                        <ChartCategoryAxis>
                            <ChartCategoryAxisItem
                                labels={{ font: "0.8rem pretendard-R", rotation: { angle: 'auto' }, content: categorylabes  }}
                                axisCrossingValue={axisCrossingValue}
                                majorGridLines={{ step: 100 }}
                                minorGridLines={{ step: 100 }} 
                                >
                            </ChartCategoryAxisItem>
                        </ChartCategoryAxis>

                    </Chart>
                </GridLayoutItem>


            </GridLayout>

        </>
    );




};

export default Progress2
