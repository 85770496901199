import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { filterBy, process } from "@progress/kendo-data-query";
import axios from 'axios';
import { ColumnMenu } from "./columnMenu";
import { CustomColumnMenu } from './customColumnMenu';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import {
  setExpandedState,
  setGroupIds
} from "@progress/kendo-react-data-tools";
import { ExcelExport } from '@progress/kendo-react-excel-export';
import '@progress/kendo-theme-default/dist/all.css';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}


function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}


const CostTable = () => {

  const { height, width } = useWindowDimensions();
  const [dashdata, setdashdata] = React.useState([]);
  const [projects, setProjects] = React.useState([]);

  const [issuestage, setStage] = React.useState("");
  const [revison, setRevision] = React.useState("");
  const [cpms, setCPMS] = React.useState([]);

  const [projects2, setProjects2] = React.useState(_.uniqBy(dashdata, 'project_projectname').map(v => v.project_projectname)[0]);

  const [issuestage2, setStage2] = React.useState("");
  const [revison2, setRevision2] = React.useState("");
  const [cpms2, setCPMS2] = React.useState([]);

  const [projectState, setprojectState] = React.useState('');
  const [issuestageState, setissuestageState] = React.useState('');
  const [revisonState, setrevisonState] = React.useState('');
  const [cpmsState, setcpmsState] = React.useState('');
  const [projectState2, setprojectState2] = React.useState('');
  const [issuestageState2, setissuestageState2] = React.useState('');
  const [revisonState2, setrevisonState2] = React.useState('');
  const [cpmsState2, setcpmsState2] = React.useState('');


  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`${process.env.REACT_APP_BASEURL}/getJSON?path=${process.env.REACT_APP_FILEPATH}/files/Qcharts/cost.json`)
      setdashdata(res.data)
      if (res.data.length > 0) {
        const initialProjects = _.uniqBy(res.data, 'project_projectname').map(v => v.project_projectname);
        setprojectState(initialProjects[0]);
        setprojectState2(initialProjects[1]);
      }
      
    }
    fetchData();

  }, []);

  const handleChangeP1 = (event) => {
    setprojectState(event.target.value);
  };


  const handleChangeI1 = (event) => {
    setissuestageState(event.target.value);
  };

  const handleChangeR1 = (event) => {
    setrevisonState(event.target.value);
  };


  const handleChangeC1 = (event) => {
    setcpmsState(event.target.value);
  };

  const handleChangeP2 = (event) => {
    setprojectState2(event.target.value);
  };


  const handleChangeI2 = (event) => {
    setissuestageState2(event.target.value);
  };

  const handleChangeR2 = (event) => {
    setrevisonState2(event.target.value);
  };


  const handleChangeC2 = (event) => {
    setcpmsState2(event.target.value);
  };




  useEffect(() => {
    setProjects(_.uniqBy(dashdata, 'project_projectname').map(v => v.project_projectname))
    setProjects2(_.uniqBy(dashdata, 'project_projectname').map(v => v.project_projectname))
  }, [dashdata]);


  useEffect(() => {
    setStage(_.uniqBy(dashdata.filter(v => v.project_projectname === projectState), 'IssueStage_txt').map(v => v.IssueStage_txt).filter(v => v !== null))
    setissuestageState(_.uniqBy(dashdata.filter(v => v.project_projectname === projectState), 'IssueStage_txt').map(v => v.IssueStage_txt).filter(v => v !== null)[0])
  }, [dashdata, projectState]);


  useEffect(() => {
    setRevision(
      _.uniqBy(
        dashdata.filter(v => projectState === v.project_projectname && issuestageState === v.IssueStage_txt), 'GenRevision'
      )
        .map(v => v.GenRevision).filter(v => v !== null))
        setrevisonState(
          _.uniqBy(
            dashdata.filter(v => projectState === v.project_projectname && issuestageState === v.IssueStage_txt), 'GenRevision'
          )
            .map(v => v.GenRevision).filter(v => v !== null)[0]
        )
  }, [dashdata, projectState, issuestageState]);

  useEffect(() => {
    setCPMS(_.uniqBy(dashdata.filter(v => projectState === v.project_projectname && issuestageState === v.IssueStage_txt && revisonState === v.GenRevision), 'CPMSVer_dpk')
      .map(v => v.CPMSVer_dpk).filter(v => v !== null))

      setcpmsState(
        _.uniqBy(dashdata.filter(v => projectState === v.project_projectname && issuestageState === v.IssueStage_txt && revisonState === v.GenRevision), 'CPMSVer_dpk')
      .map(v => v.CPMSVer_dpk).filter(v => v !== null)[0]
      )
  }, [dashdata, projectState, issuestageState, revisonState]);


  /////////////
  useEffect(() => {
    setStage2(
      _.uniqBy(dashdata.filter(v => v.project_projectname === projectState2), 'IssueStage_txt').map(v => v.IssueStage_txt).filter(v => v !== null)
      )
    setissuestageState2(
      _.uniqBy(dashdata.filter(v => v.project_projectname === projectState2), 'IssueStage_txt').map(v => v.IssueStage_txt).filter(v => v !== null)[0]
    )
  }, [dashdata, projectState2]);


  useEffect(() => {
    setRevision2(
      _.uniqBy(
        dashdata.filter(v => projectState2 === v.project_projectname && issuestageState2 === v.IssueStage_txt), 'GenRevision'
      )
        .map(v => v.GenRevision).filter(v => v !== null))
        setrevisonState2(
          _.uniqBy(
            dashdata.filter(v => projectState2 === v.project_projectname && issuestageState2 === v.IssueStage_txt), 'GenRevision'
          )
            .map(v => v.GenRevision).filter(v => v !== null)[0]
        )
  }, [dashdata, projectState2, issuestageState2]);

  useEffect(() => {
    setCPMS2(_.uniqBy(dashdata.filter(v => projectState2 === v.project_projectname && issuestageState2 === v.IssueStage_txt && revisonState2 === v.GenRevision), 'CPMSVer_dpk')
      .map(v => v.CPMSVer_dpk).filter(v => v !== null))
      setcpmsState2(_.uniqBy(dashdata.filter(v => projectState2 === v.project_projectname && issuestageState2 === v.IssueStage_txt && revisonState2 === v.GenRevision), 'CPMSVer_dpk')
      .map(v => v.CPMSVer_dpk).filter(v => v !== null)[0])
  }, [dashdata, projectState2, issuestageState2, revisonState2]);


  ////////테이블 데이터 정리

  const initialDataState = {
    // take: 10,
    skip: 0,
    group: [
      {
        field: "room"
      },

    ]
  };
  const aggregates = [
    {
      field: "sum",
      aggregate: "sum"
    },

  ];

  const processWithGroups = (data, dataState) => {
    const groups = dataState.group;
    if (groups) {
      groups.map((group) => (group.aggregates = aggregates));
    }
    dataState.group = groups;
    const newDataState = process(data, dataState);
    setGroupIds({
      data: newDataState.data,
      group: dataState.group
    });
    return newDataState;
  };

  const [result, setResult] = React.useState(
    processWithGroups(
      dashdata
        .filter(v =>
          (v.project_projectname === projectState
            && v.IssueStage_txt === issuestageState
            && v.GenRevision === revisonState
            && v.CPMSVer_dpk === cpmsState) ||
          (v.project_projectname === projectState2
            && v.IssueStage_txt === issuestageState2
            && v.GenRevision === revisonState2
            && v.CPMSVer_dpk === cpmsState2)
        ),

      initialDataState)
  );

  useEffect(() => {
    setResult(
      processWithGroups(
        dashdata
          .filter(v =>
            (v.project_projectname === projectState
              && v.IssueStage_txt === issuestageState
              && v.GenRevision === revisonState
              && v.CPMSVer_dpk === cpmsState) ||
            (v.project_projectname === projectState2
              && v.IssueStage_txt === issuestageState2
              && v.GenRevision === revisonState2
              && v.CPMSVer_dpk === cpmsState2)
          ),

        initialDataState)
    )
  }, [cpmsState2, dashdata, cpmsState, projectState, projectState2, issuestageState2, issuestageState, revisonState, revisonState2])

  const [collapsedState, setCollapsedState] = React.useState([]);

  const [dataState, setDataState] = React.useState(initialDataState);


  const dataStateChange = (event) => {
    const newDataState = processWithGroups(dashdata
      .filter(v =>
        (v.project_projectname === projectState
          && v.IssueStage_txt === issuestageState
          && v.GenRevision === revisonState
          && v.CPMSVer_dpk === cpmsState) ||
        (v.project_projectname === projectState2
          && v.IssueStage_txt === issuestageState2
          && v.GenRevision === revisonState2
          && v.CPMSVer_dpk === cpmsState2)
      ), event.dataState);
    setResult(newDataState);
    setDataState(event.dataState);
  };

  const expandChange = (event) => {
    const item = event.dataItem;
    if (item.groupId) {
      const newCollapsedIds = !event.value
        ? [...collapsedState, item.groupId]
        : collapsedState.filter((groupId) => groupId !== item.groupId);
      setCollapsedState(newCollapsedIds);
    }
  };

  const newData = setExpandedState({
    data: result.data,
    collapsedIds: collapsedState
  });

  const transformedData = _.sortBy((dashdata
    .filter(v =>
      (v.project_projectname === projectState
        && v.IssueStage_txt === issuestageState
        && v.GenRevision === revisonState
        && v.CPMSVer_dpk === cpmsState) ||
      (v.project_projectname === projectState2
        && v.IssueStage_txt === issuestageState2
        && v.GenRevision === revisonState2
        && v.CPMSVer_dpk === cpmsState2)
    )).map(obj => {
      const newObj = {};
      for (let key in obj) {
        if (key !== "project_projectname" && key !== "version" && key !== "room" &&
          key !== "GenRevision" && key !== "IssueStage_txt" && key !== "CPMSVer_dpk" &&
          key !== "spocMDisc" && !key.includes("비중")) {
          newObj[key] = typeof obj[key] === "string" ? Number(obj[key].replace(/,/g, "")) : obj[key];
        } else {
          newObj[key] = obj[key];
        }
      }
      return newObj;
    }), 'room');


  const result2 = transformedData.reduce((acc, current) => {
    if (current.room !== acc.prevRoom) {
      if (Object.keys(acc.sumObj).length !== 0) {
        const sumTotal = Object.entries(acc.sumObj).reduce((total, [key]) => {
          total[key] = transformedData.reduce((sum, item) => {
            if (typeof item[key] === 'number' && item.room === acc.prevRoom) {
              sum += item[key];
            }
            return sum;
          }, 0);
          return total;
        }, {});
        acc.result.push(sumTotal);
      }
      acc.sumObj = {};
      acc.sumKeys.clear();
    }

    Object.entries(current).forEach(([key, value]) => {
      if (typeof value === 'number') {
        acc.sumObj[key] = (acc.sumObj[key] || 0) + value;
        acc.sumKeys.add(key);
      }
    });

    acc.result.push(current);
    acc.prevRoom = current.room;
    return acc;
  }, { result: [], prevRoom: '', sumObj: {}, sumKeys: new Set() }).result;


  const result3 = result2
    .map(item => {
      const keys = Object.keys(item);
      const sumKeys = keys.filter(key => key.includes('합계 금액'));

      sumKeys.forEach(key => {
        const newValue = item[key];
        const newKey = key.replace('합계 금액', '비중');
        item[newKey] = newValue / _.sumBy(transformedData, key);
      });

      return item;
    });



  const cellRender = (tdElement, cellProps) => {
    if (cellProps.rowType === "groupFooter") {
      if (cellProps.field === projectState + '합계 금액' || cellProps.field === projectState2 + '합계 금액') {
        const sumValue = _.sumBy(
          transformedData.filter(f => f.room === cellProps.dataItem.value)
          ,
          cellProps.field
        );

        const total = _.sumBy(transformedData, cellProps.field)

        if (sumValue) {
          return (
            <td aria-colindex={cellProps.columnIndex} role={"gridcell"} style={{ whiteSpace: 'nowrap', textAlign: 'left', padding: 8 }}>
              {sumValue.toLocaleString()} | {((sumValue / total) * 100).toFixed(1)}%
            </td>
          );
        }
      }
    }
    return tdElement;
  };

  const TotalCell = props => {
    const field = props.field || '';
    const total = _.sumBy(transformedData.filter(v => typeof v.project_projectname !== 'undefined'), field)
    return <td colSpan={props.colSpan} style={props.style}>
      {total && (total / 100000000).toFixed(2) + "억원"}
    </td>;
  };

  const _export = useRef(null);

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };


  return (
    <>
      <div style={{ display: 'flex' }}>
        <div style={{ margin: 12 }}>
          <div style={{ fontSize: '0.8rem' }}>
            첫번째 프로젝트: {cpmsState ? projectState + " " + issuestageState + " " + revisonState + " " + cpmsState : ''}
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: 8 }}>
              <div style={{ verticalAlign: 'middle', fontSize: '0.6rem' }}>Project: </div>
              <DropDownList
                style={{
                  width: "180px", fontSize: '0.8rem', height: 28, fontWeight: 600,
                }}
                data={projects}
                value={projectState}
                onChange={handleChangeP1}
              />
            </div>

            {issuestage && (
              <div style={{ marginRight: 8 }}>
                <div style={{ verticalAlign: 'middle', fontSize: '0.6rem' }}>Issue Stage: </div>
                <DropDownList
                  style={{
                    width: "180px", fontSize: '0.8rem', height: 30, fontWeight: 600,
                  }}
                  data={issuestage}
                  value={issuestageState}
                  onChange={handleChangeI1}
                />
              </div>
            )}


            {revison && (
              <div style={{ marginRight: 8 }}>
                <div style={{ verticalAlign: 'middle', fontSize: '0.6rem' }}>Revision: </div>
                <DropDownList
                  style={{
                    width: "180px", fontSize: '0.8rem', height: 30, fontWeight: 600,
                  }}
                  data={revison}
                  value={revisonState}
                  onChange={handleChangeR1}
                />
              </div>

            )}

            {cpms && (
              <div style={{ marginRight: 8 }}>
                <div style={{ verticalAlign: 'middle', fontSize: '0.6rem' }}>CPMS Version: </div>
                <DropDownList
                  style={{
                    width: "180px", fontSize: '0.8rem', height: 30, fontWeight: 600,
                  }}
                  data={cpms}
                  value={cpmsState}
                  onChange={handleChangeC1}
                />
              </div>
            )}

          </div>
        </div>

        <div style={{ margin: 12 }}>
          <div style={{ fontSize: '0.8rem' }}>
            두번째 프로젝트: {cpmsState2 ? projectState2 + " " + issuestageState2 + " " + revisonState2 + " " + cpmsState2 : ''}
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: 8 }}>
              <div style={{ verticalAlign: 'middle', fontSize: '0.6rem' }}>Project: </div>
              <DropDownList
                style={{
                  width: "180px", fontSize: '0.8rem', height: 28, fontWeight: 600,
                }}
                data={projects2}
                value={projectState2}
                onChange={handleChangeP2}
              />
            </div>

            {issuestage2 && (
              <div style={{ marginRight: 8 }}>
                <div style={{ verticalAlign: 'middle', fontSize: '0.6rem' }}>Issue Stage: </div>
                <DropDownList
                  style={{
                    width: "180px", fontSize: '0.8rem', height: 30, fontWeight: 600,
                  }}
                  data={issuestage2}
                  value={issuestageState2}
                  onChange={handleChangeI2}
                />
              </div>
            )}


            {revison2 && (
              <div style={{ marginRight: 8 }}>
                <div style={{ verticalAlign: 'middle', fontSize: '0.6rem' }}>Revision: </div>
                <DropDownList
                  style={{
                    width: "180px", fontSize: '0.8rem', height: 30, fontWeight: 600,
                  }}
                  data={revison2}
                  value={revisonState2}
                  onChange={handleChangeR2}
                />
              </div>

            )}

            {cpms2 && (
              <div style={{ marginRight: 8 }}>
                <div style={{ verticalAlign: 'middle', fontSize: '0.6rem' }}>CPMS Version: </div>
                <DropDownList
                  style={{
                    width: "180px", fontSize: '0.8rem', height: 30, fontWeight: 600,
                  }}
                  data={cpms2}
                  value={cpmsState2}
                  onChange={handleChangeC2}
                />
              </div>
            )}

          </div>
        </div>
      </div>
      <ExcelExport data={result3} ref={_export}>

        <Grid
          style={{
            height: (height - 260), marginTop: 10, width: dataState.group.length > 0 ? 995 : 960, marginLeft: 10
          }}
          data={newData}
          onDataStateChange={dataStateChange}
          {...dataState}
          onExpandChange={expandChange}
          expandField="expanded"
          lockGroups={true}
          groupable={{
            footer: "visible"
          }}
          cellRender={cellRender}
          className='qcost'
          size={'small'}

        >
          <GridToolbar>
            {result3.length>0?
            <button
              title="Export Excel"
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
              onClick={excelExport}
              style={{ position: 'absolute', right: '5px' }}
            >
              <span className="k-icon k-i-file-excel"></span>
            </button>
            :""
            }
          </GridToolbar>
          <Column field="room" title="Room Lev1" width={150} locked={true} />
          <Column field="spocMDisc" title="중공종" width={250} locked={true} />
          {cpmsState &&
            <Column title={projectState}  >
              <Column field={projectState + '합계 금액'} title='합계 금액' width={200} footerCell={TotalCell} />
              <Column field={projectState + '비중'} title='비중' width={80} />
            </Column>
          }

          {cpmsState2 &&
            <Column title={projectState2} >
              <Column field={projectState2 + '합계 금액'} title='합계 금액' width={200} footerCell={TotalCell} />
              <Column field={projectState2 + '비중'} title='비중' width={80} />
            </Column>
          }
        </Grid>
      </ExcelExport>

    </>
  );
};

export default CostTable