import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import '@progress/kendo-theme-fluent/dist/all.css';
import ProjectDash from './dash/first';
import ProgressDash from './dash/progressA';
import VendorDash from './dash/vendorprogress';
import QDash from './dash/qchart';
import CostTable from './dash/costtable';
import QunatityTable from './dash/qtable';
import OCR from './orc';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Switch>
      {/* <Route exact path="/:project_code/table" component={Table} /> */}
      <Route exact path="/:project_code/project" component={ProjectDash} />
      <Route exact path="/:project_code/progress" component={ProgressDash} />
      <Route exact path="/:project_code/vendorprogress" component={VendorDash} />
      <Route exact path="/:project_code/qty" component={QDash} />
      <Route exact path="/cost" component={CostTable} />
      <Route exact path="/quantity" component={QunatityTable} />
      <Route exact path="/ocr" component={OCR} />

      

    </Switch>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
