import React, { useState, useEffect } from 'react';
import { TileLayout } from "@progress/kendo-react-layout";
// import '@progress/kendo-theme-default/dist/all.css';
import useBreakpoint from "use-breakpoint";
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import '../custom.css'
import Vendor1S from './vendor1speed';
import Vendor2S from './vendor2speed';


const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280, };

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const VendorDash = () => {

  const { height, width } = useWindowDimensions();
  const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop');
  const { project_code } = useParams();

  const GRID_CONFIG = {
    mobile:[

        {
            col: 1,
            colSpan: 18,
            rowSpan: 3,
            status: true,
          },
          {
            col: 1,
            colSpan: 18,
            rowSpan: 3,
            status: true,
          },
  
  
      ]
    
    ,

    tablet: [

        {
            col: 1,
            colSpan: 18,
            rowSpan: 2,
            status: true,
          },
          {
            col: 1,
            colSpan: 18,
            rowSpan: 4,
            status: true,
          },
  
      ],

    desktop: [

      {
        col: 1,
        colSpan: 18,
        rowSpan: 5,
        status: true,
      },
      {
        col: 1,
        colSpan: 18,
        rowSpan: 7,
        status: true,
      },

      

    ]
  }

  const GRIDCONFIG_filterd = { mobile: _.filter(GRID_CONFIG['mobile'], { 'status': true }), tablet: _.filter(GRID_CONFIG['tablet'], { 'status': true }), desktop: _.filter(GRID_CONFIG['desktop'], { 'status': true }) }

  useEffect(() => {
    setPositionData(GRIDCONFIG_filterd[breakpoint])
  }, [breakpoint]);

  // const [dashdata, setdashdata] = useState([]);
              
  // useEffect(() => {
  //   const fetchData = async () => {

  //       const res = await axios.get(`${process.env.REACT_APP_BASEURL}/getJSON?path=${process.env.REACT_APP_FILEPATH}/files/Activity2/${project_code}.json`)
  //       setdashdata(res.data)
  //   }

  //   fetchData();
  // }, [project_code]);


  const [BoxHeight, setBoxHeight] = useState(250);
  const [positiondata, setPositionData] = useState(GRID_CONFIG['desktop']);
      
  useEffect(() => {
    const boxHeight = positiondata[2];
    setBoxHeight(((height - 210) / 6) * (boxHeight && boxHeight.rowSpan));
}, [positiondata,height]);

  const tiles = [{
    header: (<div style={{ position: 'relative' }}>업체별 공정률</div>),
    body: <Vendor1S/>,
    status: true,
    resizable: false,
    reorderable: false,

  },
  {
    header: (<div style={{ position: "relative" }}>업체별 공정률 S-Curve</div>),
    body: <Vendor2S/>,
    status: true,
    resizable: false,
    reorderable: false,
  }, 
 
  ];

  const [tile, settile] = useState(tiles);
  useEffect(() => {
    settile(tiles);
  }, [positiondata,  BoxHeight]);

  const tile_filtered = _.filter(tile, ['status', true]);


  return (
    <>

      <TileLayout
        columns={18}
        rowHeight={(height - 210) / 12}
        positions={positiondata}
        gap={{
          rows: 10,
          columns: 10
        }}
        items={tile_filtered}
        autoFlow="row dense"
        className='allpage'
        style={{ height: height - 10, width: 'calc(100% - 210)' }}
      />

    </>
  )

    ;
};

export default VendorDash;