import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import axios from 'axios';
import {
    Card, CardTitle, CardBody, CardImage,
    GridLayout,
    GridLayoutItem,
} from "@progress/kendo-react-layout";
import { Divider, List, ListItem, ListItemText } from '@mui/material'
import moment from 'moment'

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}


function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

const Intro = () => {
    const { height, width } = useWindowDimensions();

    const [data, setdata] = useState([])
    const { project_code } = useParams();
    const [C_Weather, setWeather] = useState([]);
    const [DailyWeather, setDailyWeather] = useState([]);
    const [param, setparam] = useState([]);


    useEffect(() => {
        const fetchData = async () => {

            let body =
            {
                "bpname": "Project Summary",
                "lineitem": "no",
                "filter_criteria": {
                    "join": "AND",
                    "filter": [
                        {
                            "field": "status",
                            "value": 'Active',
                            "condition_type": "eq"
                        },

                    ]
                }
            }
            const res = await axios.post(`${process.env.REACT_APP_BASEURL}/getbprecords?path=${project_code}`, body)
            setdata(res.data.data.data[0])


            const projects = await axios.get(`${process.env.REACT_APP_BASEURL}/getproject`)


            const lat_o = projects.data.data.data.filter(function (el) {
                return el.ugenProjectNumber === project_code
            })[0].uuu_latitude;
            const lon_o = projects.data.data.data.filter(function (el) {
                return el.ugenProjectNumber === project_code
            })[0].uuu_longitude;

            const param = lat_o + "," + lon_o



            // const w = await axios.get(`${process.env.REACT_APP_BASEURL}/getwether?path=${param}`)
            const w = await axios.get(`http://api.weatherapi.com/v1/forecast.json?key=9a59ad2f11324645bc101847221403&q=${param}&days=5&aqi=yes`)


            const w_result = w.data && w.data.current
            const daily_w = w.data.forecast && w.data.forecast.forecastday


            setWeather(w_result)
            setDailyWeather(daily_w)


        };

        fetchData();
    }, [project_code, param]);

    function convert(value) {
        return moment.unix(value).format("MM/DD");
    }


    function krconvert(value) {
        return moment.unix(value).format("YYYY년 MM월 DD일  HH시");
    }

    const BoxHeight = (height - 350) * 0.3


    return (
        <>
            <GridLayout style={{ height: (height - 240) }}
                rows={[
                    {
                        height: '37%',
                    },
                    {
                        height: '28%',
                    },
                    {
                        height: '35%',
                    }

                ]}

                cols={[
                    {
                        width: '100%',
                    }
                ]}
                gap={{
                    rows: 10,
                    cols: 0,
                }}>

                <GridLayoutItem row={1} col={1} colSpan={2} style={{ position: 'relative' }}>
                    {data &&
                        <div dangerouslySetInnerHTML={{ __html: data.SitePhotoRT }} className='richimag' style={{ maxHeight:200, }}></div>
                    }
                {/* </GridLayoutItem> */}
                {/* <GridLayoutItem row={2} col={1} style={{ overflow: 'auto' }}> */}

                    <List dense={true} style={{ width: '90%', margin: '0 auto' }}>
                        <ListItem style={{ borderBottom: '1px solid rgb(112, 112, 112, 0.2)', paddingLeft: '0' }}>
                            <ListItemText className='listleft' style={{ width: '40%', color: '#707070', fontFamily: 'Pretendard-B' }}>Project Name</ListItemText>
                            <ListItemText className='listright' style={{ width: '60%' }}>{data && data.ugenProjectName}</ListItemText>
                        </ListItem>
                        <ListItem style={{ borderBottom: '1px solid rgb(112, 112, 112, 0.2)', paddingLeft: '0' }}>

                            <ListItemText className='listleft' style={{ width: '40%', color: '#707070', fontFamily: 'Pretendard-B' }}>Location</ListItemText>
                            <ListItemText className='listright' style={{ width: '60%' }}>{data && data.address}</ListItemText>
                        </ListItem>

                        <ListItem style={{ borderBottom: '1px solid rgb(112, 112, 112, 0.2)', paddingLeft: '0' }}>


                            <ListItemText className='listleft' style={{ width: '40%', color: '#707070', fontFamily: 'Pretendard-B' }}>Client</ListItemText>
                            <ListItemText className='listright' style={{ width: '60%' }}>{data && data.genClient}</ListItemText>
                        </ListItem>
                        <ListItem style={{ borderBottom: '1px solid rgb(112, 112, 112, 0.2)', paddingLeft: '0' }}>


                            <ListItemText className='listleft' style={{ width: '40%', color: '#707070', fontFamily: 'Pretendard-B' }}>공사 기간</ListItemText>
                            <ListItemText className='listright' style={{ width: '60%' }}>{data && data.PJTPeriod}</ListItemText>
                        </ListItem>

                        <ListItem style={{ borderBottom: '0', paddingLeft: '0' }}>

                            <ListItemText className='listleft' style={{ width: '40%', color: '#707070', fontFamily: 'Pretendard-B' }}>공사범위</ListItemText>
                            <ListItemText className='listright' style={{ width: '60%', overflowY:'auto' , maxHeight:100, fontSize:'0.75rem'}}>{data && data.WorkScopeMT && data.WorkScopeMT.replaceAll('\\n','\n')}</ListItemText>
                        </ListItem>
                    </List>
                </GridLayoutItem>
                <GridLayoutItem row={3} col={1} >
                <Divider />

                    <GridLayout style={{ height: BoxHeight }}
                        rows={[
                            {
                                height: '11%',
                            },
                            {
                                height: '11%',
                            },
                            {
                                height: '11%',
                            },
                            {
                                height: '11%',
                            },
                            {
                                height: '11%',
                            },
                            {
                                height: '11%',
                            },
                            {
                                height: '11%',
                            },
                            {
                                height: '11%',
                            },
                            {
                                height: '11%',
                            },
                            {
                                height: '11%',
                            },
                            {
                                height: '11%',
                            },

                        ]}

                        cols={[
                            {
                                width: '25%',
                            },
                            {
                                width: '25%',
                            },
                            {
                                width: '25%',
                            },
                            {
                                width: '25%',
                            },
                        ]}
                        gap={{
                            rows: 5,
                            cols: 0,
                        }}>
                        <GridLayoutItem col={1} row={1} colSpan={4} rowSpan={1} style={{textAlign:'center', padding:'6px'}}>
                            <span style={{fontSize:'0.9rem'}}>{C_Weather && krconvert(C_Weather.last_updated_epoch)}</span>
                        </GridLayoutItem>

                        <GridLayoutItem col={1} row={2} colSpan={2} rowSpan={3} style={{ height: (BoxHeight / 2)-10, textAlign: 'center' , position:'relative'}} className='center'>
                            <img
                                width={'30%'}
                                style={{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)' }}
                                alt="171x180"
                                src={_.get(C_Weather, 'condition.icon')} />
                        </GridLayoutItem>
                        <GridLayoutItem col={3} row={2} colSpan={2} rowSpan={3} style={{ height: (BoxHeight / 2)-10, textAlign: 'center', fontSize: '0.9rem', position:'relative' }} className='center'>
                            <div style={{ fontSize: '0.9rem' ,position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)' ,width:'100%'}}> {C_Weather && C_Weather.temp_c}℃ <div > 체감온도:{C_Weather && C_Weather.feelslike_c}℃ </div> </div>
                        </GridLayoutItem>

                        <GridLayoutItem col={1} row={5} colSpan={1} rowSpan={1} style={{ textAlign: 'center', fontSize: `${(BoxHeight / 300) * 1}rem` }}>
                            <img className="icon" alt="icon" src={'/image/humidity.png'} width="20%" /><div>{C_Weather && C_Weather.humidity}% </div>
                        </GridLayoutItem>
                        <GridLayoutItem col={2} row={5} colSpan={1} rowSpan={1} style={{ textAlign: 'center', fontSize: `${(BoxHeight / 300) * 1}rem` }}>
                            <img className="icon" alt="icon" src={'/image/wind.png'} width="20%" /><div>{C_Weather && C_Weather.wind_dir} {C_Weather && C_Weather.wind_kph}km/h </div>
                        </GridLayoutItem>
                        <GridLayoutItem col={3} row={5} colSpan={1} rowSpan={1} style={{ textAlign: 'center', fontSize: `${(BoxHeight / 300) * 1}rem` }}>
                            <img className="icon" alt="icon" src={'/image/sunset.png'} width="20%" /><div>{DailyWeather && _.get(DailyWeather[0], 'astro.sunset')}</div>
                        </GridLayoutItem>
                        <GridLayoutItem col={4} row={5} colSpan={1} rowSpan={1} style={{ textAlign: 'center', fontSize: `${(BoxHeight / 300) * 1}rem` }}>
                            <img className="icon" alt="icon" src={'/image/air-quality.png'} width="20%" /><div> {Number(_.get(C_Weather, 'air_quality.pm2_5')).toFixed(2)}㎍/m³</div>
                        </GridLayoutItem>
                        <GridLayoutItem col={1} row={6} colSpan={4} rowSpan={1} style={{ textAlign: 'center' }}>
                        </GridLayoutItem>


                        <GridLayoutItem col={1} row={7} colSpan={4} rowSpan={5} style={{ textAlign: 'center', }}>
                            <div className='k-card-group '>
                                {DailyWeather && DailyWeather.map((pi, index) => {
                                    return (
                                        <Card orientation="vertical" key={index} style={{ width: '20%' }}>
                                            <CardImage style={{ width: BoxHeight / 10, margin: '0 auto' }} src={pi.day.condition.icon} />
                                            <CardTitle style={{ fontSize: '0.6rem', marginBottom: '0' }}>  {convert(pi.date_epoch)} </CardTitle>
                                            <CardBody style={{ padding: '5px', marginBottom: '0' }}>
                                                <CardTitle style={{ fontSize: '0.6rem', marginBottom: '1px' }}>기온:  {pi.day.avgtemp_c}℃</CardTitle>
                                                <CardTitle style={{ fontSize: '0.6rem', marginBottom: '1px' }}>습도: {pi.day.avghumidity}%</CardTitle>
                                                <CardTitle style={{ fontSize: '0.6rem' }}>강수확률: {pi.day.daily_chance_of_rain}%</CardTitle>
                                            </CardBody>
                                        </Card>
                                    )

                                })}
                            </div>
                        </GridLayoutItem>


                    </GridLayout>
                </GridLayoutItem>

            </GridLayout>

        </>
    );

}

export default Intro
