import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import axios from "axios";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
  ChartTitle,
} from "@progress/kendo-react-charts";
import "hammerjs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const Vendor1S = () => {
  const { height, width } = useWindowDimensions();
  const [vendordata, setvendordata] = useState([]);
  const [dlist, setdlist] = useState([]);
  const { project_code } = useParams();
  console.log(project_code);
  const [state, setState] = React.useState(dlist[0]);
  const handleChange = (event) => {
    setState(event.target.value);
  };

  const [iconstate, seticonstate] = useState([]);

  
  useEffect(() => {
    const fetchData = async () => {

      let body =
      {
        "bpname": "업체 등록",
        "lineitem": "no",
        "filter_criteria": {
          "join": "AND",
          "filter": [
            {
              "field": "status",
              "value": 'Active',
              "condition_type": "eq"
            },

          ]
        }
      }
      const res = await axios.post(`${process.env.REACT_APP_BASEURL}/getbprecords?path=${project_code}`, body)

      const vendors = res.data.data.data.map((v, index) => ({ 공종: v.DisciplineSPD, companyName: v.uveVendorNameTB50, id: index }))
      
      seticonstate(_.uniqBy(vendors, 'companyName').map((v)=>({id:v.id, state:true})))
      setvendordata(vendors)
      setdlist(_.uniqBy(vendors, '공종').map(v => v['공종']))

    }
    fetchData()
  }, [project_code])

  const [data2, setdata2] = useState([]);

    useEffect(() => {
        const fetchData = async () => {

            const res = await axios.get(`${process.env.REACT_APP_BASEURL}/progresscontrol2?project=${project_code}`)
            setdata2(res.data.data)

        };

        fetchData();

    }, [ project_code]);


const progressby =
data2.map((v) => ({ ...v, 공종: vendordata && vendordata.find(vf => vf.companyName === v.companyName) && vendordata.find(vf => vf.companyName === v.companyName)['공종'] }))

  const lastvendor = state ? progressby.filter(v => v['공종'] === state) : progressby


    
  useEffect(() => {
    seticonstate(lastvendor && lastvendor.map((v, index)=>({id:index, state:true})))
  },[project_code])

  const handle2 = (e) => {
    e.preventDefault();
    const value = e.target.getAttribute("value");
    const state = {id:Number(value), state:iconstate && iconstate.find(v=>v.id === Number(value)) && !iconstate.find(v=>v.id === Number(value))['state']}
    seticonstate(state && iconstate.map((v)=>({...v,  ...[state].find(vf=>vf.id === v.id)})));
  };


  const colmunNo = Math.round(lastvendor.length / 2);

  const chartwidth =
    (width / colmunNo / 2) * 0.95 > 200 ? 200 : (width / colmunNo / 2) * 0.95;

  const columnList = [];

  for (var i = 1; i <= colmunNo * 2; i++) {
    columnList.push({ width: `${100 / colmunNo / 2}%` });
  }

  const hidden = {
    visible: false,
  };

  const SharedTooltip = (props) => {
    const { points } = props;
    return (
      <div>
        <div>계획: {points[0].value.target.toFixed(2)}%</div>
        <div>실적: {points[0].value.current.toFixed(2)}%</div>
        <div>
          차이: {(points[0].value.current - points[0].value.target).toFixed(2)}%
        </div>
      </div>
    );
  };

  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;



  return (
    <>
      <GridLayout
        style={{ height: (height - 280) *5/12, width: (width - 48) }}
        rows={[
          {
            height: "0%",
          },
          {
            height: "0%",
          },
          {
            height: "15%",
          },
          {
            height: "35%",
          },
          {
            height: "15%",
          },
          {
            height: "35%",
          },
        ]}
        cols={columnList}
        gap={{
          rows: 0,
          cols: 0,
        }}
      >

        <ComboBox
          style={{
            width: "100px", float: 'right', fontSize: '0.7rem', height: 20, fontWeight: 600, position: 'absolute', top: 6, right: 6
          }}
          data={dlist}
          value={state}
          onChange={handleChange}
        />



        {lastvendor.map((v, index) => (
          <GridLayoutItem
            key={index}
            col={
              index + 1 > columnList.length * 0.5
                ? index * 2 + 1 - columnList.length
                : index * 2 + 1
            }
            row={index + 1 > columnList.length * 0.5 ? 5 : 3}
            colSpan={2}
            rowSpan={2}
            style={{ textAlign: "center" }}
          >

            <button class="k-icon k-i-hyperlink-open-sm" style={{border:'none',borderRadius:'2px' ,position:'relative', zIndex:999, top:'20%', right:'-40%', cursor:'pointer', 
            color: 
            iconstate&&iconstate[index] && iconstate[index]['state'] ? 
            '#34568B'
            : '#FA7A35'
          }} 
              onClick={handle2}
              value={index}
            ></button>

            
          {iconstate[index] && iconstate[index]['state'] ?
            <Chart
              style={{
                height: (height - 380) * 5/12 / 2, marginTop:-20
              }}
            >
              <ChartTooltip shared={true} render={sharedTooltipRender} />

              <ChartTitle
                text={`${v.companyName} \n ${(v.Actual - v.Plan).toFixed(
                  2
                )}% \n (계획:${v.Plan.toFixed(2)}% 실적:${v.Actual.toFixed(
                  2
                )}%)\n\n`}
                font="0.8rem pretendard-B"
              />
              <ChartSeries>
                <ChartSeriesItem
                  type="bullet"
                  color={v.Actual - v.Plan < 0 ? "#CD212A" : "#00A170"}
                  data={[v.Actual, v.Plan]}
                />
              </ChartSeries>
              <ChartValueAxis>
                <ChartValueAxisItem
                  majorGridLines={hidden}
                  minorTicks={hidden}
                  min={0}
                  max={100}
                  labels={{ font: "0.7rem Arial, KoPub" }}
                />
              </ChartValueAxis>
            </Chart>

            :
         
                <>
                <div style={{font:'0.8rem pretendard-B'}}>{v.companyName}</div>
                <br/>

                <div style={{font:'0.7rem pretendard-B'}}>현황별 액티비티 개수</div>

                <div style={{font:'0.7rem pretendard-B'}}>
              <span>전체: {v.total}</span>&nbsp;&nbsp;
              <span>선행: {v.ahead}</span>&nbsp;&nbsp;
              <span>지연: {v.behind}</span>
              </div>
              </>
          
                }
            {/* <div style={{fontSize:'0.8rem', fontWeight:'600'}}>{v.d_company_name}</div><div  style={{fontSize:'0.8rem', fontWeight:'600'}}>(계획:{v.Plan.toFixed(2)}% 실적:{v.Actual.toFixed(2)}%)</div> 
                        <div style={{position:'relative', top:'70px' ,fontSize:'1.2rem', fontWeight:'600', color:'#34568B'}}>{v.Dev.toFixed(2)}%</div>
                        <div style={{ position:'relative', top:'-60px', left:chartwidth/2}}>
                        <ArcGauge color='#0072B5' value={v.Actual} style={{width:chartwidth, overflow:'visible'}} scale={{ rangeLineCap:'butt', rangeSize:15}}  />
                        <ArcGauge color='#CD212A' value={v.Plan} style={{width:chartwidth,opacity:'0.2', position:'relative', left:chartwidth*-1 ,overflow:'visible'}} scale={{ rangeLineCap:'butt', rangeSize:15}}  />
                        </div> */}
          </GridLayoutItem>
        ))}


      </GridLayout>
    </>
  );
};

export default Vendor1S;
