import { Milestones } from 'react-milestones-vis';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';

const Milestone = ({ BoxHeight}) => {


    const { project_code } = useParams();
    const [sourcedata, setdata2] = useState([]);

    useEffect(() => {
        const fetchData = async () => {

            let body =
            {
                "bpname": "Project Summary",
                "lineitem": "yes",
                "filter_criteria": {
                    "join": "AND",
                    "filter": [
                      {
                        "field": "status",
                        "value": 'Active',
                        "condition_type": "eq"
                      },
                  
                    ]
                  }

            }
            const res = await axios.post(`${process.env.REACT_APP_BASEURL}/getbprecords?path=${project_code}`, body)
            setdata2(res.data.data.data[0]['_bp_lineitems'].filter(v=>v.MilestoneCategorySRB ==='Key Milestone'))

        };

        fetchData();
    }, [project_code]);


    // const data = [
    //     {date:'2017-04-14',title:'착공'},
    //     {date:'2021-01-10',title:'Hauling System'},
    //     {date:'2021-06-22',title:'PPWS Cable/Compaction'},
    //     {date:'2021-07-20',title:'Clamp & Hanger'},
    //     {date:'2021-12-19',title:'Deck Erection & Welding'},
    //     {date:'2022-01-17',title:'조기개통'},
    //     {date:'2022-06-17',title:'준공'},
    //     {date:'2022-08-17',title:''},

    // ]

    const data = sourcedata.filter(v =>  v.MPlanDateDO !== null)
    .map((v) => ({ date:  v.MActualDateDO !== null? moment(v.MActualDateDO.slice(0,10)).format('YYYY-MM-DD'): moment(v.MPlanDateDO).format('YYYY-MM-DD'), 
    title: 
    v.MActualDateDO !== null? v.MilestoneName + '(Plan: '+moment(v.MPlanDateDO).format('YYYY-MM-DD')+')' : 
    v.MilestoneName
    , 
    
    titleStyle: {color: v.MActualDateDO !== null && parseFloat(moment(v.MActualDateDO).diff(moment(v.MPlanDateDO),'days')) > 0 ? '#A2242F' :v.MActualDateDO !== null && parseFloat(moment(v.MActualDateDO).diff(moment(v.MPlanDateDO),'days')) <= 0?'#00A170': 'black'}, }))
    const data2 = sourcedata.filter(v => v.MActualDateDO !== null).map((v) => ({ date: moment(v.MPlanDateDO).format('YYYY-MM-DD'), title: v.MilestoneName }))

    // const data2 = [
    //     {date:'2017-04-14',title:'착공'},
    //     {date:'2021-01-10',title:'Hauling System'},
    //     {date:'2021-06-22',title:'PPWS Cable/Compaction'},
    //     {date:'2021-07-20',title:'Clamp & Hanger'},
    //     {date:'2021-12-19',title:'Deck Erection & Welding'},
    //     {date:'2022-01-17',title:'조기개통', titleStyle : {
    //         color : "red",
    //         'font-weight': 'bold'
    //         }},
    //     {date:'2022-08-17',title:'준공'},
    // ]

    return (
        <>
            <div style={{ height: BoxHeight/2 , marginTop: (0.5 * BoxHeight) / 2}}>

                {/* <div style={{ height: (0.3 * BoxHeight) / 2 }}> */}
                    <Milestones
                        aggregateBy="day"
                        data={data}
                        mapping={{
                            text: 'title',
                            timestamp: 'date'
                        }}
                        optimize={true}
                        parseTime="%Y-%m-%d"
                        distribution='top-bottom'
                        autoResize={true}
                    />
                {/* </div> */}
                {/* <div style={{ height: (0.1 * BoxHeight) / 2, marginTop: (-0.3 * BoxHeight) / 2 }}>
                    <Milestones
                        aggregateBy="day"
                        data={data}
                        mapping={{
                            text: 'title',
                            timestamp: 'date'
                        }}
                        optimize={true}
                        parseTime="%Y-%m-%d"
                        distribution='bottom'
                        autoResize={true}
                        style={{overflow:'auto'}}

                    />
                </div> */}
            </div>
        </>
    );

};

export default Milestone
