import React, { useState, useEffect, useMemo, useLayoutEffect } from 'react';
import _ from 'lodash';
import {
  Card,
  CardTitle,
  GridLayout, GridLayoutItem
} from "@progress/kendo-react-layout";
import moment from 'moment';

// import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import MuiToggleButton from '@mui/material/ToggleButton';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Spirited from "@amcharts/amcharts5/themes/Spirited";

am5.addLicense("AM5C363108325"); 
am5.addLicense("AM5M363108325"); 

const CHART_ID = 'manchart';


const ToggleButton = styled(MuiToggleButton)(() => ({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: '#4B5335',
  },
  '&.MuiToggleButton-root': {
    fontSize: '0.6rem',
    fontFamily: 'Pretendard-R',
    padding: 0,
    borderRadius: '5px 5px 5px 5px!important',
    border: '1px solid rgba(0, 0, 0, 0.12)!important',
    width: 60

  }
}));

const ToggleButtonGroup = styled(MuiToggleButtonGroup)(() => ({
  '&.MuiToggleButtonGroup-root': {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1)
    }
  }
}));

const ToggleButton2 = styled(MuiToggleButton)(() => ({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: '#FF6F61',
  },
  '&.MuiToggleButton-root': {
    fontSize: '0.6rem',
    fontFamily: 'Pretendard-R',
    padding: 0,
    width: 60,
    borderRadius: '5px 5px 5px 5px!important',
    border: '1px solid rgba(0, 0, 0, 0.12)!important'

  }
}));

const ToggleButtonGroup2 = styled(MuiToggleButtonGroup)(() => ({
  '&.MuiToggleButtonGroup-root': {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1)
    }
  }
}));


const theme = createTheme({
  palette: {
    text: {
      primary: '#00ff00',
    },
  },
});

const ManPower = ({ drdata, BoxHeight, datevalue }) => {


  const [chartData, setChartData] = useState([]);

  const [selectedValue, setSelectedValue] = React.useState("daily");
  const [selectedValue2, setSelectedValue2] = React.useState("직종");


  const handleChange = (event, selectedValue) => {
    if (selectedValue !== null) {
      setSelectedValue(selectedValue);
    }
  };


  const handleChange2 = (event, selectedValue2) => {
    if (selectedValue2 !== null) {
      setSelectedValue2(selectedValue2);
    }
  };

  useEffect(() => {

    let todaydate = datevalue.setHours(0, 0, 0, 0)

    const linedata = drdata
    .filter(v=>v._bp_lineitems !== undefined)
    .reduce((c, v) => c.concat(v._bp_lineitems.map(o => Object.assign(o, { ReportingDO: v.ReportingDO, PALContractorBPK: v.PALContractorBPK }))), [])
      .filter(v => v.uuu_tab_id === '투입인원')
      .filter(item => {
        let itemDate = new Date(item.ReportingDO);
        itemDate.setHours(0, 0, 0, 0);
        return itemDate <= todaydate
      })
      .map((v) => ({ ...v, uniq1: v.DRJobTitleBPK + "//" + v.ReportingDO, uniq2: v.PALContractorBPK + "//" + v.ReportingDO }))


    const chartdata =
      selectedValue2 === '직종' ?
        _(linedata)
          .groupBy("uniq1")
          .map((objs, key) => ({
            date: new Date(key.split('//')[1].slice(0, 10)),
            xtype: key.split("//")[0].split(' | ')[1],
            count: _.sumBy(objs, "DRResAssignDayDA"),
          }))
          .value() :

        _(linedata)
          .groupBy("uniq2")
          .map((objs, key) => ({
            date: new Date(key.split('//')[1].slice(0, 10)),
            xtype: key.split("//")[0],
            count: _.sumBy(objs, "DRResAssignDayDA"),
          }))
          .value()

    const transformedData = _(chartdata)
      .groupBy('date')
      .map((objs, date) => ({
        date,
        ..._(objs)
          .keyBy('xtype')
          .mapValues('count')
          .value(),
      }))
      .value()
      .map((v)=>({...v, none:0}))

    const transformedDataweek = transformedData.map((v) => ({ ...v, date: moment(v.date).format("YY년 WW주") }))


    const groupedData = _.groupBy(transformedDataweek, 'date');
    const transformedDatabyWeek = _.sortBy(Object.keys(groupedData).map(date => {
      const group = groupedData[date];
      const sumData = { date };

      group.forEach(obj => {
        Object.keys(obj).forEach(key => {
          if (key !== 'date') {
            sumData[key] = (sumData[key] || 0) + obj[key];
          }
        });
      });

      return sumData;
    }), 'date').map((v)=>({...v, none:0}))

    const transformedDatDay = _.sortBy(transformedData.map((v) => ({ ...v, date: moment(v.date).format("YY년 MM월 DD일") })), "date")
    const transformedDatamonth =_.sortBy(transformedData.map((v) => ({ ...v, date: moment(v.date).format("YY년 MM월") })), "date")


    const groupedDataM = _.groupBy(transformedDatamonth, 'date');
    const transformedDatabyMonth = _.sortBy(Object.keys(groupedDataM).map(date => {
      const group = groupedDataM[date];
      const sumData = { date };

      group.forEach(obj => {
        Object.keys(obj).forEach(key => {
          if (key !== 'date') {
            sumData[key] = (sumData[key] || 0) + obj[key];
          }
        });
      });

      return sumData;
    }), 'date').map((v)=>({...v, none:0}))



    setChartData(selectedValue === 'daily' ? transformedDatDay.slice(-5) : selectedValue === 'weekly' ? transformedDatabyWeek.slice(-5) : transformedDatabyMonth.slice(-5))


  }, [drdata, selectedValue, selectedValue2, datevalue]);


  function sumNumericValues(obj) {
    let sum = 0;

    for (let key in obj) {
      if (typeof obj[key] === 'number') {
        sum += obj[key];
      }
    }

    return sum;
  }


  const total_man_day = sumNumericValues(chartData.filter(v => v.date === moment(datevalue).format("YY년 MM월 DD일"))[0])
  const total_man_week = sumNumericValues(chartData.filter(v => v.date === moment(datevalue).format("YY년 WW주"))[0])
  const total_man_month = sumNumericValues(chartData.filter(v => v.date === moment(datevalue).format("YY년 MM월"))[0])



  useLayoutEffect(() => {
    let root = am5.Root.new(CHART_ID);
    root.setThemes([am5themes_Animated.new(root), am5themes_Spirited.new(root)]);

    var chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: "panX",
      wheelY: "zoomX",
      layout: root.verticalLayout
    }));

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
      xAxis: xAxis
    }));
    cursor.lineY.set("visible", false);

    // add scrollbar
    var scrollbar = chart.set("scrollbarX", am5.Scrollbar.new(root, {
      orientation: "horizontal",
      maxHeight: 3
    }));

    scrollbar.startGrip.set("scale", 0.7);
    scrollbar.endGrip.set("scale", 0.7);

    chart.zoomOutButton.set("forceHidden", true);



    var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "date",
      renderer: am5xy.AxisRendererX.new(root, {
        cellStartLocation: 0.1,
        cellEndLocation: 0.9,
        minGridDistance: 30
      }),
      tooltip: am5.Tooltip.new(root, {})
    }));

    xAxis.data.setAll(chartData);

    xAxis.get("renderer").labels.template.setAll({
      oversizedBehavior: "fit",
      maxWidth: 50
    });


    var yRenderer = am5xy.AxisRendererY.new(root, {})
    yRenderer.labels.template.set('visible', false)
    
    var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      calculateTotals: true,
      min: 0,
      extraMax: 0.1,
      renderer: yRenderer,
    }));

    yAxis.get("renderer").grid.template.set("forceHidden", true);
    xAxis.get("renderer").grid.template.set("forceHidden", true);



    var legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.percent(50),
      x: am5.percent(50),
      // layout: root.horizontalLayout,
      // layout: am5.GridLayout.new(root, {
      //   maxColumns: 3,
      //   // fixedWidthGrid: true
      // }),
      height: am5.percent(30),
      width: am5.percent(100),
      verticalScrollbar: am5.Scrollbar.new(root, {
        orientation: "horizental"
      }),
    }));



    function makeSeries(name, fieldName, showTotal) {
      var series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: name,
        stacked: true,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: fieldName,
        categoryXField: "date",
        legendLabelText: "[fontSize: 10px {stroke}]{name}[/] {valueY} ",
        maskBullets: false
      }));

      series.columns.template.setAll({
        width: am5.percent(90),
      });

      series.columns.template.setAll({

        tooltipText: "[bold]{name}[/]\n {valueY}명",
        tooltipY: am5.percent(10)
      });




      series.bullets.push(function (root, series, dataItem) {
        if (dataItem.get("valueY") / sumNumericValues(dataItem.dataContext) > 0.2) {
          return am5.Bullet.new(root, {
            sprite: am5.Label.new(root, {
              text: dataItem.get("valueY"),
              fill: root.interfaceColors.get("alternativeText"),
              centerX: am5.p50,
              centerY: am5.p50,
              minBulletDistance: 20
            })
          });
        }
      });


      if (showTotal) {
 
        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            locationY: 1,
            sprite: am5.Label.new(root, {
              text: "{valueYTotal}",
              fill: am5.color(0x000000),
              centerY: am5.p100,
              centerX: am5.p50,
              populateText: true
            })
          });
        });

      }


      series.data.setAll(chartData);
      series.appear();
      
      if (!showTotal) {
        legend.data.push(series);
      }

    }

    const keys = _(chartData)
      .flatMap(Object.keys)
      .uniq()
      .pull('date')
      .pull('none')
      .value();

    for (const key of keys) {
      makeSeries(key, key, false);
    }

    makeSeries("", "none", true);


    chart.appear(1000, 100);

    return () => {
      root && root.dispose();
    };

  }, [chartData]);

  return (
    <>
      {/* <div style={{fontSize: '0.9rem',top:'8px' ,color: '#F1F2F5', zIndex: '9999999' ,position: 'absolute', fontWeight: '500', right: '12px'}}>{moment().format('YYYY년 MM월 DD일')}</div> */}

      <div style={{ marginLeft: '12px', position: 'absolute', top: '80px', transform: 'translateY(-50%)' }}>
        {selectedValue === 'daily' ?
          <div><span style={{ fontSize: '0.7rem', color: '#363945', fontWeight: '600' }}> 기준일 </span><span style={{ fontSize: '1.1rem', color: '#363945', fontWeight: '600' }}>{total_man_day.toLocaleString()}</span> <span style={{ fontSize: '0.7rem', color: '#363945', fontWeight: '600' }}> 명 </span></div> :
          selectedValue === 'weekly' ?
            <div><span style={{ fontSize: '0.7rem', color: '#363945', fontWeight: '600' }}> 기준일 주간 </span><span style={{ fontSize: '1.1rem', color: '#363945', fontWeight: '600' }}>{total_man_week.toLocaleString()}</span> <span style={{ fontSize: '0.7rem', color: '#363945', fontWeight: '600' }}> 명 </span></div> :
            selectedValue === 'monthly' ?
              <div><span style={{ fontSize: '0.7rem', color: '#363945', fontWeight: '600' }}> 기준일 월간 </span><span style={{ fontSize: '1.1rem', color: '#363945', fontWeight: '600' }}>{total_man_month.toLocaleString()}</span> <span style={{ fontSize: '0.7rem', color: '#363945', fontWeight: '600' }}> 명 </span></div> : ''
        }
      </div>
      <div style={{ position: 'absolute', top: '55px', right: '12px', transform: 'translateY(-50%)' }} className='manRB'>
        <ThemeProvider theme={theme}>
          <ToggleButtonGroup size="small" onChange={handleChange} value={selectedValue} exclusive aria-label="baseunit">
            <ToggleButton value="daily" aria-label='daily' >
              daily
            </ToggleButton>
            <ToggleButton value="weekly" aria-label="weekly">
              weekly
            </ToggleButton>
            <ToggleButton value="monthly" aria-label="monthly">
              monthly
            </ToggleButton>
          </ToggleButtonGroup>
        </ThemeProvider>

      </div>

      <div style={{ position: "absolute", top: "85px", right: '12px', transform: 'translateY(-50%)', zIndex: 999999 }}>
        <ThemeProvider theme={theme}>
          <ToggleButtonGroup2 size="small" onChange={handleChange2} value={selectedValue2} exclusive aria-label="baseunit">
            <ToggleButton2 value="직종" aria-label='직종' >
              직종
            </ToggleButton2>
            <ToggleButton2 value="업체별" aria-label="업체별">
              업체별
            </ToggleButton2>
          </ToggleButtonGroup2>
        </ThemeProvider>
      </div>
      <div id={CHART_ID} style={{ width: "100%", height: BoxHeight - 80, zIndex: '999', margin: '0 auto', marginTop: 35 }}></div>

    </>
  );
};

export default ManPower;