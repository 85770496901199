import React, { useState, useEffect, useMemo } from 'react';
import { TileLayout } from "@progress/kendo-react-layout";
// import '@progress/kendo-theme-default/dist/all.css';
import useBreakpoint from "use-breakpoint";
import _ from 'lodash';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import '../custom.css'
import Qchart from './quantitychart';
import Qchart2 from './quantitychart2';
import Qchart3 from './quantitychart3';
import Qchart4 from './quantitychart4';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import Qtable from './quantitytable';

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280, };

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const QDash = () => {

  const { height, width } = useWindowDimensions();
  const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop');
  const { project_code } = useParams();

  const GRID_CONFIG = {
    mobile: [
      {
        col: 1,
        colSpan: 18,
        rowSpan: 3,
        status: true,
      },
      {
        col: 1,
        colSpan: 18,
        rowSpan: 3,
        status: true,
      },
      {
        col: 1,
        colSpan: 18,
        rowSpan: 3,
        status: true,
      },

      {
        col: 1,
        colSpan: 18,
        rowSpan: 3,
        status: true,
      },
      {
        col: 1,
        colSpan: 18,
        rowSpan: 3,
        status: true,
      },


    ]

    ,

    tablet: [


      {
        col: 1,
        colSpan: 9,
        rowSpan: 3,
        status: true,
      },

      {
        col: 10,
        colSpan: 9,
        rowSpan: 3,
        status: true,
      },
      {
        col: 1,
        colSpan: 9,
        rowSpan: 3,
        status: true,
      },

      {
        col: 10,
        colSpan: 9,
        rowSpan: 3,
        status: true,
      },

      {
        col: 1,
        colSpan: 18,
        rowSpan: 3,
        status: true,
      },




    ],

    desktop: [

      {
        col: 1,
        colSpan: 9,
        rowSpan: 3,
        status: true,
      },

      {
        col: 10,
        colSpan: 9,
        rowSpan: 3,
        status: true,
      },
      {
        col: 1,
        colSpan: 9,
        rowSpan: 3,
        status: true,
      },

      {
        col: 10,
        colSpan: 9,
        rowSpan: 3,
        status: true,
      },

      {
        col: 1,
        colSpan: 18,
        rowSpan: 3,
        status: true,
      },





    ]
  }

  const GRIDCONFIG_filterd = { mobile: _.filter(GRID_CONFIG['mobile'], { 'status': true }), tablet: _.filter(GRID_CONFIG['tablet'], { 'status': true }), desktop: _.filter(GRID_CONFIG['desktop'], { 'status': true }) }

  useEffect(() => {
    setPositionData(GRIDCONFIG_filterd[breakpoint])
  }, [breakpoint]);



  const [BoxHeight, setBoxHeight] = useState(250);
  const [positiondata, setPositionData] = useState(GRID_CONFIG['desktop']);


  useEffect(() => {
    const boxHeight = positiondata[2];
    setBoxHeight(((height - 210) / 6) * (boxHeight && boxHeight.rowSpan));
  }, [positiondata, height]);



  const tiles = [{
    header: (<div style={{ position: 'relative' }}>공종별 내역    </div>),
    body: <Qchart />,
    status: true,
    resizable: false,
    reorderable: false,

  },
  {
    header: (<div style={{ position: "relative" }}>Area별 내역</div>),
    body: <Qchart2 />,
    status: true,
    resizable: false,
    reorderable: false,
  },


  {
    header: (<div style={{ position: 'relative' }}>공종에 따른 Area별 내역</div>),
    body: <Qchart3 />,
    status: true,
    resizable: false,
    reorderable: false,
  },

  {
    header: (<div style={{ position: 'relative' }}>Area에 따른 공종별 내역</div>),
    body: <Qchart4 />,
    status: true,
    resizable: false,
    reorderable: false,
  },
  {
    header: (<div style={{ position: 'relative' }}>Stage별 내역</div>),
    body: <Qtable/>,
    status: true,
    resizable: false,
    reorderable: false,
  },


  ];

  const [tile, settile] = useState(tiles);

  useEffect(() => {
    settile(tiles);
  }, [positiondata, BoxHeight]);



  return (
    <>

      <TileLayout
        columns={18}
        rowHeight={(height - 210) / 6}
        positions={positiondata}
        gap={{
          rows: 10,
          columns: 10
        }}
        items={tile}
        autoFlow="row dense"
        className='allpage'
        style={{ height: height - 10, width: 'calc(100% - 210)' }}
      />

    </>
  )

    ;
};

export default QDash;