import React, { useEffect, useState} from 'react';
import _ from 'lodash';
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { filterBy , process} from "@progress/kendo-data-query";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { ColumnMenu } from "./columnMenu";
import { CustomColumnMenu } from './customColumnMenu';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}


function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}


const Qtable = () => {

  const { height, width } = useWindowDimensions();
  const { project_code } = useParams();
  const [sourcedata, setsourcedata] = useState([]);
  const [stateColumns, setStateColumns] = useState([]);
  const [result, setResult] = React.useState({
    data: [],
    total: 0,
  });
  const [dataState, setDataState] = React.useState({});


  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`${process.env.REACT_APP_BASEURL}/getJSON?path=${process.env.REACT_APP_FILEPATH}/files/Qunatity/${project_code}.json`)

      const sum1 = res.data.map((v) => ({ uniq: v.IssueStage_txt + '//' + v.spocHDisc + '//' + v.spocMDisc, totalprice: v.TotalPrice }))

      var groupbyType =
        _(sum1)
          .groupBy('uniq')
          .map((objs, key) => ({
            'stage': key.split('//')[0],
            '대공종': key.split('//')[1],
            '중공종': key.split('//')[2],
            'sum': _.sumBy(objs, 'totalprice'),

          }))
          .value()

      const cleandata = groupbyType.map((v) => ({ ...v, [v.stage]: v.sum ,uniq:v.대공종 + '//'+v.중공종}))


      const stageList = _.uniq(cleandata.map(v => v.stage))
      const columns = stageList.map((v)=>({field:v, title:v, show:true}))


      
      var groupbyType2 =
      _(cleandata)
        .groupBy('uniq')
        .map((objs, key) => ({
          '대공종': key.split('//')[0],
          '중공종': key.split('//')[1],
          test:objs.reduce(function(result, current) {
            return Object.assign(result, current);
          }, {})

        }))
        .value()


      const cleandata2 = groupbyType2.map(v=>v.test)

      setStateColumns(columns)
      setsourcedata(cleandata2);

      // const createDataState = dataState => {
      //   return {
      //     result: process(cleandata2, dataState),
      //     dataState: dataState
      //   };
      // };

      // let initialState = createDataState({
      //   skip: 0,    
      //   take: 0,

      // });

       

      // setResult(initialState.result)
      // setDataState(initialState.dataState)

      setResult({
        data: cleandata2,
        total: cleandata2.length,
      });


    }
    fetchData();

  }, [project_code]);



  // const createDataState = dataState => {
  //   return {
      
  //     result: {data:filterBy(sourcedata, dataState.filter), total:filterBy(sourcedata, dataState.filter).length},
  //     dataState: dataState
  //   };
  // };

  // const dataStateChange = event => {
  //   let updatedState = createDataState(event.dataState);

  //   setResult(updatedState.result);
  //   setDataState(updatedState.dataState);
  // };

  const createDataState = (dataState) => {
    return {
      result: process(sourcedata, dataState),
      dataState: dataState,
    };
  };

  const dataStateChange = (event) => {
    let updatedState = createDataState(event.dataState);

    setResult(updatedState.result);
    setDataState(updatedState.dataState);
  };

  const onColumnsSubmit = columnsState => {
    setStateColumns(columnsState);
  };

  const FooterFilterCell = (props) => {
    const field = props.field || "";
    const filter_arr = dataState.filter;

    if (filter_arr === undefined) {
      return <td colSpan={props.colSpan} style={props.style} />;
    } else {
      if (field === "대공종") {
        const filter_value = filter_arr.filters.find(
          (com) => com.filters[0]["field"] === field
        );

        return (
          <td colSpan={props.colSpan} style={props.style}>
            {filter_value !== undefined &&
              "필터: " + filter_value.filters.map((com) => com.value).join()}
          </td>
        );
      } else if (field === "중공종") {
        const filter_value = filter_arr.filters.find(
          (com) => com.filters[0]["field"] === field
        );

        return (
          <td colSpan={props.colSpan} style={props.style}>
            {filter_value !== undefined &&
              "필터: " + filter_value.filters.map((com) => com.value).join()}
          </td>
        );
      }
    }
  };


  const FootSumCell = (props) => {
    const field = props.field || "";

    const total = _.sumBy(result.data, field);

    const total2 = _.sumBy(sourcedata, field);

    // const dataState_filter =
    //   dataState.filter === undefined ? undefined : dataState.filter.filters;

    // const dataState_filter_arr =
    //   dataState_filter === undefined
    //     ? []
    //     : dataState_filter.map((com) => com.filters).flat();

    // const dataState_filter_field = dataState_filter_arr.filter(
    //   (com) => com.field === "대공종" || com.field === "중공종"
    // );

    // const dataState_filter_field_reduce = dataState_filter_field.reduce(
    //   (acc, cur) =>
    //     acc.filter((com) =>
    //       com[cur.field].toUpperCase().includes(cur.value.toUpperCase())
    //     ),
    //   sourcedata
    // );

    // const total = _.sumBy(dataState_filter_field_reduce, field);

    // const total2 = _.sumBy(sourcedata, field);

    return (
      <td colSpan={props.colSpan} style={props.style}>
        {(total / 100000000).toLocaleString()}억원 /{" "}
        {(total2 / 100000000).toLocaleString()}억원
      </td>
    );
  };

  const gridCell = (props) => {

    const field = props.field || "";
    const value = props.dataItem[field];

    return (
      <td colSpan={props.colSpan} style={props.style}>
        {(value ? (value/100000000).toLocaleString()+'억원' : '-')}
      </td>
    );
  };



  return (
    <>
      <Grid
        style={{
          height: (height - 260) * 0.35,
        }}
        // resizable={true}
        reorderable={true}
        sortable={true}
        data={result} {...dataState} onDataStateChange={dataStateChange} 
      >
        <Column field="대공종" title="대공종"  width="100px" columnMenu={ColumnMenu} footerCell={FooterFilterCell}/>
        <Column field="중공종" title="중공종"  width="200px" columnMenu={ColumnMenu} footerCell={FooterFilterCell}/>
      {stateColumns.map((column, idx) => column.show && <Column key={idx} field={column.field} title={column.title} 
      filter="numeric" width= {(width -366)/_.filter(stateColumns, {show:true}).length}      footerCell={FootSumCell} cell={gridCell} 
      columnMenu={props => <CustomColumnMenu {...props} columns={stateColumns} onColumnsSubmit={onColumnsSubmit} />} 
      />
      )}

      </Grid>
    </>
  );
};

export default Qtable