import React, { useEffect, useState, } from 'react';
import _ from 'lodash';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useParams } from 'react-router-dom';
import axios from 'axios';

am4core.useTheme(am4themes_animated);
am4core.addLicense("CH363108325");

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}


function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}


const Qchart4 = () => {

    const { height, width } = useWindowDimensions();
    const [state, setState] = React.useState('AFC');
    const [piedata, setpiedata] = useState([]);
    const [StageList, setStageList] = useState([]);
    const { project_code } = useParams();

    const handleChange = (event) => {
        setState(event.target.value);
    };

    const COLORS = ["#003f5c", "#2f4b7c", "#665191","#a05195","#d45087", "f95d6a","#ff7c43", "#ffa600"]

    useEffect(() => {
        const fetchData = async () => {
            const res = await axios.get(`${process.env.REACT_APP_BASEURL}/getJSON?path=${process.env.REACT_APP_FILEPATH}/files/Qcharts/${project_code}_chart4.json`)

            setpiedata(res.data
                .map((v) => ({ ...v, sub: v.sub.map((f, index) => ({ ...f, color: COLORS[index] })) }))

            )
            setStageList(res.data.map(v => v.IssueStage_txt))
        }
        fetchData();

    }, [project_code]);
    useEffect(() => {
        /**
     * Chart container
     */

        // Create chart instance
        var chart = am4core.create("qchart4", am4core.Container);
        chart.width = am4core.percent(100);
        chart.height = am4core.percent(100);
        chart.layout = "horizontal";

        chart.numberFormatter.bigNumberPrefixes = [
            { "number": 1e+6, "suffix": "백만" },
            { "number": 1e+8, "suffix": "억" }
          ];

        /**
         * Column chart
         */

        // Create chart instance
        var columnChart = chart.createChild(am4charts.XYChart);

        // Create axes
        var categoryAxis = columnChart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "key";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.inversed = true;
        categoryAxis.renderer.labels.template.fontSize = 12;
        categoryAxis.renderer.minGridDistance = 5;

        var valueAxis = columnChart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.numberFormatter.numberFormat = '#,###a'; 
        valueAxis.renderer.labels.template.fontSize = 12;

        // Create series
        var columnSeries = columnChart.series.push(new am4charts.ColumnSeries());
        columnSeries.dataFields.valueX = "cost";
        columnSeries.dataFields.categoryY = "key";
        columnSeries.columns.template.strokeWidth = 0;

        var valueLabel = columnSeries.bullets.push(new am4charts.LabelBullet());
        valueLabel.label.text = "{valueX.formatNumber('#,###.0a')}원";
        valueLabel.label.fontSize = 12;
        valueLabel.label.truncate = false;
        valueLabel.label.valign = 'middle';
        valueLabel.label.hideOversized = false;
        valueLabel.label.horizontalCenter = "left";

        // valueLabel.label.horizontalCenter = "right";

        /**
         * Pie chart
         */

        // Create chart instance
        var pieChart = chart.createChild(am4charts.PieChart);
        pieChart.data = piedata.filter(v => v.IssueStage_txt === state)[0] && piedata.filter(v => v.IssueStage_txt === state)[0].sub;
        pieChart.innerRadius = am4core.percent(50);

        // Add and configure Series
        var pieSeries = pieChart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "cost";
        pieSeries.dataFields.category = "key";
        pieSeries.slices.template.propertyFields.fill = "color";
        pieSeries.labels.template.disabled = true;
        pieSeries.slices.template.tooltipText = "{category}: {value.formatNumber('#,###.0a')}원";

        // Set up labels
        var label1 = pieChart.seriesContainer.createChild(am4core.Label);
        label1.text = "";
        label1.horizontalCenter = "middle";
        label1.fontSize = 35;
        label1.fontWeight = 600;
        label1.dy = -30;

        var label2 = pieChart.seriesContainer.createChild(am4core.Label);
        label2.text = "";
        label2.horizontalCenter = "middle";
        label2.fontSize = 12;
        label2.dy = 20;

        // Auto-select first slice on load
        pieChart.events.on("ready", function (ev) {
            pieSeries.slices.getIndex(0).isActive = true;
        });

        // Set up toggling events
        pieSeries.slices.template.events.on("toggled", function (ev) {
            if (ev.target.isActive) {

                // Untoggle other slices
                pieSeries.slices.each(function (slice) {
                    if (slice !== ev.target) {
                        slice.isActive = false;
                    }
                });

                // Update column chart
                columnSeries.appeared = false;
                columnChart.data = ev.target.dataItem.dataContext.sub_sub;
                columnSeries.fill = ev.target.fill;
                columnSeries.reinit();

                // Update labels
                label1.text = pieChart.numberFormatter.format(ev.target.dataItem.values.value.percent, "#.'%'");
                label1.fill = ev.target.fill;

                label2.text = ev.target.dataItem.category;
            }
        });


        return () => {
            chart && chart.dispose();

        };


    }, [piedata, state]);

    return (
        <>
            <DropDownList
                style={{
                    width: "100px", float: 'right', fontSize: '0.7rem', height: 20, fontWeight: 600, position: 'absolute', top: 6, right: 6
                }}
                data={StageList}
                value={state}
                onChange={handleChange}
            />
            <div
                id="qchart4"
                style={{ width: "95%", height: (height - 260) * 0.35, zIndex: '999', margin: '0 auto' }}
            ></div>



        </>
    );
};

export default Qchart4