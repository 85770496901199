import React, { useState, useEffect, useMemo } from 'react';
import { TileLayout } from "@progress/kendo-react-layout";
// import '@progress/kendo-theme-default/dist/all.css';
import useBreakpoint from "use-breakpoint";
import _ from 'lodash';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import ManPower from './man';
// import Equip from './equip';
import Intro from './projectinfo';
import '../custom.css'
import Milestone from './milestone';
import Progress2 from './progress1';
import ActStatus from './activitystatus';
import moment from 'moment';
import { DatePicker } from "@progress/kendo-react-dateinputs";
import {
    IntlProvider,
    load,
    LocalizationProvider,
    loadMessages,
} from "@progress/kendo-react-intl";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/ko/numbers.json";
import currencies from "cldr-numbers-full/main/ko/currencies.json";
import caGregorian from "cldr-dates-full/main/ko/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/ko/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/ko/timeZoneNames.json"
import krMessages from "./kr.json";
load(
  likelySubtags,
  currencyData,
  weekData,
  numbers,
  currencies,
  caGregorian,
  dateFields,
  timeZoneNames
);
loadMessages(krMessages, "ko-KR");


const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280, };

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const ProjectDash = () => {

  const { height, width } = useWindowDimensions();
  const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop');
  const { project_code } = useParams();
  const defaultvalue = new Date()

  const [datevalue, setValue] = useState(defaultvalue);
  const GRID_CONFIG = {
    mobile: [

      {
        col: 1,
        colSpan: 18,
        rowSpan: 3,
        status: true,
      },
      {
        col: 1,
        colSpan: 18,
        rowSpan: 3,
        status: true,
      },
      {
        col: 1,
        colSpan: 18,
        status: true,
        rowSpan: 3,
      },
      {
        col: 1,
        colSpan: 18,
        status: true,
        rowSpan: 3,
      }, {
        col: 1,
        colSpan: 18,
        status: true,
        rowSpan: 3,
      },


    ]

    ,

    tablet: [

      {
        col: 1,
        colSpan: 4,
        rowSpan: 6,
        status: true,
      },
      {
        col: 5,
        colSpan: 4,
        rowSpan: 3,
        status: true,
      },
      {
        col: 9,
        colSpan: 10,
        rowSpan: 3,
        status: true,
      },
      {
        col: 5,
        colSpan: 4,
        rowSpan: 3,
        status: true,
      },
      {
        col: 9,
        colSpan: 10,
        rowSpan: 3,
        status: true,
      },


    ],

    desktop: [

      {
        col: 1,
        colSpan: 4,
        rowSpan: 6,
        status: true,
      },

      {
        col: 5,
        colSpan: 10,
        rowSpan: 2,
        status: true,
      },
      {
        col: 15,
        colSpan: 4,
        rowSpan: 3,
        status: true,
      },

      {
        col: 5,
        colSpan: 10,
        rowSpan: 4,
        status: true,
      },
      {
        col: 15,
        colSpan: 4,
        rowSpan: 3,
        status: true,
      },


    ]
  }

  const GRIDCONFIG_filterd = { mobile: _.filter(GRID_CONFIG['mobile'], { 'status': true }), tablet: _.filter(GRID_CONFIG['tablet'], { 'status': true }), desktop: _.filter(GRID_CONFIG['desktop'], { 'status': true }) }

  useEffect(() => {
    setPositionData(GRIDCONFIG_filterd[breakpoint])
  }, [breakpoint]);

  const [drdata, setdrdata] = useState([]);
  const [filelist, setfilelist] = useState([]);


  useEffect(() => {
    const fetchData = async () => {

      const res = await axios.get(`${process.env.REACT_APP_BASEURL}/getFileFolderList?path=${process.env.REACT_APP_FILEPATH}/files/DR/${project_code}/`)

      console.log(res)
      // if (res.data.length >0){
      const files = res.data.fileList
      setfilelist(files)
    }

    fetchData();
  }, [project_code]);



  const DRfilelist = useMemo(() => filelist, [filelist]);


  useEffect(() => {
    const fetchData = async () => {
      const requestArr3 = DRfilelist.map(async data => {
        return axios.get(`${process.env.REACT_APP_BASEURL}/getJSON?path=${process.env.REACT_APP_FILEPATH}/files/DR/${project_code}/${data.name}`)
      });

      const res3 = await Promise.all(requestArr3)
      const reslist = res3.map(v => v.data).flat(1)
      const last = _.uniqBy(reslist.map((v) => ({ ...v, record_uniq: v.project_projectnumber + v.record_no })), 'record_uniq')


      setdrdata(last)


    }
    fetchData();
  }, [DRfilelist]);


console.log(drdata)
  const [BoxHeight, setBoxHeight] = useState(250);
  const [positiondata, setPositionData] = useState(GRID_CONFIG['desktop']);

  useEffect(() => {
    const boxHeight = positiondata[2];
    setBoxHeight(((height - 210) / 6) * (boxHeight && boxHeight.rowSpan));
  }, [positiondata, height]);

  const tiles = [{
    header: (<div style={{ position: 'relative' }}>프로젝트 개요</div>),
    body: <Intro />,
    status: true,
    resizable: false,
    reorderable: false,

  },
  {
    header: (<div style={{ position: "relative" }}>프로젝트 마일스톤</div>),
    body: <Milestone BoxHeight={BoxHeight} />,

    status: true,
    resizable: false,
    reorderable: false,
  },
  {
    header: (<div style={{ position: "relative" }}>현장 투입 인력 현황</div>),
    body: <ManPower drdata={drdata} BoxHeight={BoxHeight} datevalue={datevalue}/>,

    status: true,
    resizable: false,
    reorderable: false,
  },
  {
    header: (<div style={{ position: 'relative' }}>공정률</div>),
    body: <Progress2 datevalue={datevalue}/>,
    status: true,
    resizable: false,
    reorderable: false,
  },

  // {
  //   header: (<div style={{ position: 'relative' }}>장비 현황</div>),
  //   body: <Equip drdata={drdata} BoxHeight={BoxHeight}/>,

  //   status: true,
  //   resizable: false,
  //   reorderable: false,
  // },

  {
    header: (<div style={{ position: 'relative' }}>공종별 차질 액티비티 현황</div>),
    body: <ActStatus datevalue={datevalue}/>,

    status: true,
    resizable: false,
    reorderable: false,
  },

  ];

  const [tile, settile] = useState(tiles);

  useEffect(() => {
    settile(tiles);
  }, [positiondata, drdata, BoxHeight,datevalue]);

  const tile_filtered = _.filter(tile, ['status', true]);



  const handleChange = (event) => {
    setValue(event.target.value);
};

  return (
    <>
    <div style={{ position: 'absolute', right: 222, fontSize: '0.85rem', top:11 , fontWeight:600}}>
      데이터 기준일: 
</div>
    <div style={{ position: 'absolute', right: 16, fontSize: '0.8rem', top:3 }}>
      <LocalizationProvider language="ko-KR">
        <IntlProvider locale="ko-KR">
          <DatePicker format={"yyyy년 MM월 dd일 EEEE"} onChange={handleChange} value={datevalue} />
        </IntlProvider>
      </LocalizationProvider>

      {/* {moment().add(-1, 'days').format('YYYY년 MM월 DD일')} 기준  */}
      
      </div>

      <TileLayout
        columns={18}
        rowHeight={(height - 210) / 6}
        positions={positiondata}
        gap={{
          rows: 10,
          columns: 10
        }}
        items={tile_filtered}
        autoFlow="row dense"
        className='allpage'
        style={{ height: height - 10, width: 'calc(100% - 210)', paddingTop: 40 }}
      />

    </>
  )

    ;
};

export default ProjectDash;